
function blobToDataURL(blob) {
  return new Promise(resolve => {
    const fileReader = new FileReader
    fileReader.onload = ev => {
      resolve(ev.target.result)
    }
    fileReader.readAsDataURL(blob)
  })
}

function blobToArrayBuffer(blob) {
  return new Promise(resolve => {
    const fileReader = new FileReader
    fileReader.onload = ev => {
      resolve(ev.target.result)
    }
    fileReader.readAsArrayBuffer(blob)
  })
}

function blobToImage(blob) {
  return new Promise(resolve => {
    const image = new Image
    image.onload = () => {
      resolve(image)
    }
    image.src = URL.createObjectURL(blob)
  })
}

// image の長辺サイズが size になるように、縮小処理を行う
// すでにsize以下の場合は、何もしない

function resizeImage(image, size) {
  // console.log('JPEG TRANSCODE START', image.width, image.height) // DEBUG:

  do {
    let w = image.width
    let h = image.height
    if(size < w || size < h) {
      w *= .5
      h *= .5
      if(w < size && h < size) {
        if(w < h) {
          w = image.width * size / image.height
          h = size
        }
        else {
          w = size
          h = image.height * size / image.width
        }
      }
      w = Math.floor(w)
      h = Math.floor(h)
    }

    const canvas = document.createElement('canvas')
    canvas.width = w
    canvas.height = h

    const context = canvas.getContext('2d')
    const scale = w / image.width
    context.setTransform(scale, 0, 0, scale, 0, 0)
    context.drawImage(image, 0, 0)

    image = canvas

    // console.log('SCALE TO', w, h, scale) // DEBUG:

  } while(size < image.width || size < image.height)

  return image
}

// image の縦横比が width:height になるように切り詰める

function cropImage(image, width, height) {

  const w = Math.round(image.height * width / height)
  if(w < image.width) {

    const canvas = document.createElement('canvas')
    canvas.width = w
    canvas.height = image.height

    const context = canvas.getContext('2d')
    context.drawImage(image, Math.round((image.width - w) * .5), 0, w, image.height, 0, 0, w, image.height)

    return canvas
  }

  const h = Math.round(image.width * height / width)
  if(h < image.height) {

    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = h

    const context = canvas.getContext('2d')
    context.drawImage(image, 0, Math.round((image.height - h) * .5), image.width, h, 0, 0, image.width, h)

    return canvas
  }

  return image
}

// exif orientation 値を取得する
// 1=Normal, 2=MirrorH, 3=Rot180, 4=MirrorV, 5=MirrorH+Rot270, 6=Row90, 7=MirrorH+Rot90, 8=Rot270, -1=エラー

function getExifOrientationFromArrayBuffer(arrayBuffer) {
  const view = new DataView(arrayBuffer)
  if(view.getUint16(0, false) !== 0xffd8) {
    return -1
  }
  const length = view.byteLength
  let offset = 2
  while(offset < length) {
    const marker = view.getUint16(offset, false)
    offset += 2
    if(marker === 0xffe1) {
      offset += 2
      if(view.getUint32(offset, false) !== 0x45786966) {
        break
      }
      offset += 6
      const little = view.getUint16(offset, false) === 0x4949
      offset += view.getUint32(offset + 4, little)
      const tags = view.getUint16(offset, little)
      offset += 2
      for(let i = 0; i < tags; ++i) {
        if(view.getUint16(offset + (i * 12), little) === 0x112) {
          return view.getUint16(offset + (i * 12) + 8, little)
        }
      }
    }
    else if((marker & 0xff00) === 0xff00) {
      offset += view.getUint16(offset, false)
    }
    else break
  }
  return -1
}

async function getExifOrientationFromBlob(blob) {
  const arrayBuffer = await blobToArrayBuffer(blob)
  return getExifOrientationFromArrayBuffer(arrayBuffer)
}

// image の orientation 値を修正する

function correctOrientationImage(image, orientation) {

  // TODO: 未実装

  return image
}

//

function canvasToDataURL(canvas, type, quality) {
  quality = parseFloat(quality)
  if(isNaN(quality)) {
    quality = undefined
  }
  return canvas.toDataURL(type, quality)
}


export {
  blobToDataURL,
  blobToArrayBuffer,
  blobToImage,
  resizeImage,
  cropImage,
  getExifOrientationFromArrayBuffer,
  getExifOrientationFromBlob,
  correctOrientationImage,
  canvasToDataURL,
}
