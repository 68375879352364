
<template lang="pug">
aq-page
  template(v-slot:header-title)
    slot

    .row.items-center.q-gutter-sm
      a-filter-input(v-model="tableState")

  template(v-slot:default)
    a-lazy-table.full-height(
      v-model="tableState"
      api-url="/_api/queue/failed"
      row-key="id"
      :columns="columns"
    )
</template>

<script>
import AFilterInput from '@/aax/components/AFilterInput.vue'
import ALazyTable   from '@/aax/components/ALazyTable.vue'

export default {
  components: {AFilterInput, ALazyTable},

  data() {
    return {
      tableState: {},
    }
  },

  computed: {
    columns() {
      return [
        {
          name: 'id',
          align: 'left',
          sortable: true,
          label: 'ID',
        },
        {
          name: 'connection',
          align: 'left',
          sortable: true,
          label: 'Connection',
        },
        {
          name: 'queue',
          align: 'left',
          sortable: true,
          label: 'Queue',
        },
        {
          name: 'class',
          align: 'left',
          sortable: true,
          label: 'Class',
        },
        {
          name: 'exception',
          align: 'left',
          sortable: true,
          label: 'Exception',
        },
        {
          name: 'failed_at',
          align: 'left',
          sortable: true,
          label: 'Failed At',
        },
      ]
    },
  },
}
</script>
