
<template lang="pug">
q-dialog(
  v-bind="$attrs"
  v-model="dialog"
  :persistent="persistent"
  :no-esc-dismiss="noEscDismiss"
  :no-backdrop-dismiss="noBackdropDismiss"
  @hide="onDialogHide"
)
  q-card.q-dialog-plugin
    template(v-if="html")
      q-card-section.q-dialog__title(
        v-if="title"
        v-html="title"
      )

      q-card-section.q-dialog__message.scroll(
        v-if="message"
        v-html="message"
      )

    template(v-else)
      q-card-section.q-dialog__title(
        v-if="title"
      ) {{title}}

      q-card-section.q-dialog__message.scroll(
        v-if="message"
      ) {{message}}

    q-card-actions(
      vertical
      align="center"
    )
      SlideSwitch.q-btn-item(
        :labelLeft="labelLeft"
        :label="label"
        :knobWidth="knobWidth"
        style="width: 200px; height: 36px"
        @confirm="onOKClick"
      )

      q-btn(
        flat
        label="キャンセル"
        @click="onCancelClick"
      )
</template>

<script>
import SlideSwitch from '@/aax/components/SlideSwitch.vue'
import sleep from './promise-sleep'

export default {
  components: {SlideSwitch},

  props: {
    title: String,
    message: String,

    html: Boolean,
    persistent: Boolean,
    noEscDismiss: Boolean,
    noBackdropDismiss: Boolean,

    labelLeft:  String,
    label:      String,
    knobWidth:  Number,
  },

  data() {
    return {
      dialog: false,
    }
  },

  methods: {
    show() {
      this.dialog = true
    },

    hide() {
      this.dialog = false
    },

    onDialogHide() {
      this.$emit('hide')
    },

    async onOKClick() {
      await sleep(300)
      this.$emit('ok', this.text)
      this.hide()
    },

    onCancelClick() {
      this.hide()
    },
  },
}
</script>
