
import deepFreeze from '@/aax/helpers/deep-freeze'

// TODO: 調整中

const defaultThemes = deepFreeze({
  colors: {
    production: {
      light: {
        toolbar:     'bg-grey-2 text-black',
        scrollThumb: 'black',
        inputLabel:  'grey-7',
      },

      dark: {
        toolbar:     'bg-grey-9',
        scrollThumb: 'white',
        inputLabel:  'grey-3',
      },
    },

    development: {
      light: {
        toolbar: 'bg-teal-2',
      },

      dark: {
        toolbar: 'bg-teal-10',
      },
    },
  },

  attrs(colors) {
    return {
      toolbar: {
        class: colors.toolbar,
      },

      scrollArea: {
        thumbStyle: {
          backgroundColor: colors.scrollThumb,
          borderRadius:    '4px',
          opacity:         .4,
          right:           '3px',
          width:           '7px',
        },
      },

      btn: {
        // unelevated: true,
      },

      field: {
        dense:      true,
        filled:     true,
        labelColor: colors.inputLabel,
      },

      // input: {
      // },

      // select: {
      // },

      // toolbarField: {
      // },

      // toolbarInput: {
      // },

      // toolbarSelect: {
      // },
    }
  },
})

export default defaultThemes
