
import {upperFirst, camelCase} from 'lodash-es'

export default function install(Vue, _options) {

  const registeredComponents = []
  const registerComponents = context => {
    for(const key of context.keys()) {
      // console.log('register component', key) // DEBUG:
      const name = upperFirst(camelCase(key.split('/').pop().split('.').shift()))
      const component = context(key)
      registeredComponents.push(name)
      Vue.component(name, component.default || component)
    }
  }

  registerComponents(require.context('@/aax/components', false, /^(?!.*\/(Ag|App|Input|Xq)).*\.vue$/))
  registerComponents(require.context('.', false, /\/\w+\.vue$/))
}
