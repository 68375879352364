
<template lang="pug">
.fit
  MaintenanceView(v-if="client_maint")
  slot(v-else)

  .aax-version
    .aax-version__maintenance(v-if="server_maint") MAINTENANCE MODE
    .aax-version__display(v-if="version") ver {{version}}
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

import MaintenanceView from './MaintenanceView.vue'

export default {
  components: {MaintenanceView},

  computed: {
    ...aax.mapState(['client_maint', 'server_maint', 'version']),
  },

  async created() {
    // ダークモード選択
    // this.$q.dark.set(true)

    // タイトル
    document.title = process.env.VUE_APP_TITLE

    // ファイルドロップ抑制
    window.addEventListener('dragover', this.onDragPrevent)
    window.addEventListener('drop', this.onDragPrevent)

    // サーバー設定
    await this.getServer()
  },

  beforeDestroy() {
    // ファイルドロップ抑制
    window.removeEventListener('dragover', this.onDragPrevent)
    window.removeEventListener('drop', this.onDragPrevent)
  },

  methods: {
    ...aax.mapActions(['getServer']),

    // ファイルドロップ抑制
    onDragPrevent(e) {
      e.preventDefault()
    },
  },
}
</script>

<style lang="sass">
.aax-version
  align-items: flex-end
  bottom: 0
  display: flex
  flex-direction: column
  font-size: 10px
  pointer-events: none
  position: fixed
  right: .2rem
  user-select: none
  z-index: 10000

.aax-version__maintenance
  color: red
  font-weight: bold

.aax-version__display
  color: #808080
</style>
