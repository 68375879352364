
<template lang="pug">
q-table.aq-table(
  ref="root"
  v-bind="$attrs"
  dense
  separator="cell"
  :virtual-scroll-item-size="28"
  :virtual-scroll-sticky-size-start="28"
  :color="color_"
  v-on="$listeners"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )
</template>

<script>
export default {
  name: 'aq-table',

  props: {
    dark:  {type: Boolean, default: null},
    color: String,
  },

  computed: {
    isDark() {
      return this.dark ?? this.$q.dark.isActive
    },

    // quasar1.12.7 時点では QTable の loading は dark mode で適切な色を選択できない
    color_() {
      return this.color ?? (this.isDark ? 'grey-3' : 'grey-8')
    },
  },

  methods: {
    ...[
      'toggleFullscreen',
      'setFullscreen',
      'exitFullscreen',
      'requestServerInteraction',
      'setPagination',
      'firstPage',
      'prevPage',
      'nextPage',
      'lastPage',
      'isRowSelected',
      'clearSelection',
      'isRowExpanded',
      'setExpanded',
      'sort',
      'resetVirtualScroll',
      'scrollTo',
    ].reduce((s, n) => ({...s, [n]() {return this.$refs.root[n](...arguments)}}), {}),

    getQTable() {
      return this.$refs.root
    },
  },
}
</script>

<style lang="sass">
.aq-table
  // ヘッダの色
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: darken(#fff, 8%)

  &.q-dark
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th
      background-color: lighten(#1d1d1d, 16%)

  // 列ヘッダ固定
  thead tr th
    position: sticky
    z-index: 1

  thead tr:last-child th
    top: 28px

  thead tr:first-child th
    top: 0

  // 最下段のボーダーを表示する → 不要？
  tbody:not(.q-virtual-scroll__padding) td
    border-bottom-width: 1px
</style>
