
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import aax          from './aax'
import apps         from './apps'
import sectionsTree from './sectionsTree'
import auths        from './auths'

export default new Vuex.Store({
  modules: {
    aax,

    // TODO: 必要かどうか検証し、不要なものは削除
    apps,
    sectionsTree,
    auths,
  },
})
