<template lang="pug">
aq-page(elevated)
  template(
    v-slot:default
    v-if="data !== null"
  )
    .absolute-center(v-if="data.length === 0") アプリが存在しません
    aq-scroll-area.full-height(v-else)
      .q-ma-lg.row
        .col-12.col-md-6.col-lg-4.q-pa-md(
          v-for="app of data"
          :key="app.id"
        )
          q-btn.full-width(
            no-caps rounded
            @click="select(app.url, $event)"
          )
            .full-width.row.q-py-sm
              x-avatar(
                :src="app.icon"
                width="80"
                height="80"
                edge-radius="15%"
              )

              .home__item-text
                .home__item-name {{app.name}}
                .home__item-desc {{app.desc}}
</template>

<script>
export default {
  $http: true,

  data() {
    return {
      data: null,
    }
  },

  async created() {
    this.data = await this.$http.$get('/_api/apps?home=1')
  },

  methods: {
    select(url, ev) {
      if(ev.shiftKey) {
        setTimeout(() => {
          window.open(url, '_blank')
        }, 0)
      }
      else {
        window.location.href = url
      }
    },
  },
}
</script>

<style lang="sass">
.home__item-text
  flex: 1 1 0
  margin-left: 12px
  text-align: left
  overflow: hidden

.home__item-name
  font-weight: bold
  font-size: 1.4em
  line-height: 1.2em
  overflow: hidden
  white-space: nowrap

.home__item-desc
  color: gray
  font-size: 0.8em
  line-height: 1.2em
  max-height: 53px
  overflow: hidden
</style>
