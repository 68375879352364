
<template lang="pug">
aq-page(elevated)
  template(v-slot:header-title)
    slot

  template(v-slot:default)
    .q-ma-lg.column.no-wrap.items-center.q-gutter-lg

      .text-red ※注意: 現状の実装では、メンテナンスモードのままセッションをログアウトすると再ログインできません。

      aq-input.maintenance__input(
        v-model="retry"
        type="number"
        label="リロード秒数 (0でリロード無効)"
        maxlength="250"
      )
      //- aq-input.maintenance__input(
      //-   v-model.trim="allow"
      //-   label="アクセス許可IP (カンマ区切りで複数指定可)"
      //-   maxlength="250"
      //- )
      //- aq-input.maintenance__input(
      //-   v-model.trim="message"
      //-   label="メッセージ"
      //-   maxlength="250"
      //- )
      aq-btn(
        color="negative"
        label="上記パラメータでメンテナンスモード開始"
        @click="onClickDown"
      )
      aq-btn(
        color="warning"
        label="メンテナンスモード終了"
        @click="onClickUp"
      )
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

export default {
  $http: true,

  data() {
    return {
      retry: 0,
      // allow: '',
      // message: '',
    }
  },

  async mounted() {
    const data = await this.$http.$get('/_api/maintenance')
    this.allow = data.external_ip.join(',')
  },

  methods: {
    ...aax.mapActions(['getServer']),

    async onClickDown() {
      const secret = await this.$http.$put('/_api/maintenance/down', {
        retry: this.retry,
        // allow: this.allow,
        // message: this.message,
      })
      await this.$http.$get('/' + secret)
      await this.getServer()
    },

    async onClickUp() {
      await this.$http.put('/_api/maintenance/up')
      await this.getServer()
    },
  },
}
</script>

<style lang="sass">
.maintenance__input
  width: 400px
</style>
