
<template lang="pug">
aq-page(:title-after="original.name" elevated)
  template(v-slot:header-title-before)
    q-btn(
      dense round unelevated
      icon="arrow_back"
      @click="back"
    )

  template(
    v-slot:header-title-after
    v-if="!overwrap"
  )
    .row.items-center.q-gutter-sm
      aq-btn(
        v-if="isNew"
        color="primary"
        icon="add"
        label="作成"
        @click="create"
      )

      aq-btn(
        v-else
        color="primary"
        icon="check"
        label="更新"
        @click="update"
      )

  template(v-slot:default)
    component(
      v-if="overwrap"
      :is="overwrap"
    )

    aq-scroll-area.full-height(v-else)
      .q-ma-md
        q-card.q-mb-md.q-pl-md.q-pt-md
          .row
            .col-12.q-pr-md.q-pb-md
              x-avatar-edit(
                ref="icon"
                v-model="model.icon"
                edge-radius="15%"
                jpeg-resolution="512"
                :error="error.icon"
              )

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="id"
                v-model.trim="model.id"
                label="UUID"
                maxlength="250"
                mask="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                :readonly="!isNew"
                :error="error.id"
              )

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="entity_id"
                v-model.trim="model.entity_id"
                autofocus
                label="Entity ID"
                maxlength="250"
                :error="error.entity_id"
              )

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="url"
                v-model.trim="model.url"
                label="トップページURL"
                maxlength="250"
                :error="error.url"
              )

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="name"
                v-model.trim="model.name"
                label="名前"
                maxlength="250"
                :error="error.name"
              )

            .col-12.q-pr-md.q-pb-md
              a-textarea(
                ref="desc"
                v-model.trim="model.desc"
                autogrow handle-tab
                label="説明"
                maxlength="65000"
                :error="error.desc"
              )

            .col-12.q-pr-md.q-pb-md
              aq-select(
                ref="status"
                v-model="model.status"
                emit-value map-options
                label="状態"
                :options="statusOpts"
                :error="error.status"
              )

            .col-12.q-pr-md.q-pb-md
              a-textarea(
                ref="entity_data"
                v-model="model.entity_data"
                autogrow handle-tab
                label="metadata JSON"
                maxlength="65000"
                :error="error.entity_data"
              )

            .col-12.q-pr-md.q-pb-md
              aq-field(:error="error.role_maps")
                x-roles-tree.q-py-sm(
                  v-model="model.role_maps"
                  map-mode
                )
</template>

<script>
import FormMixin from '@/aax/helpers/form'

export default {
  mixins: [FormMixin],

  data() {
    return {
      statusOpts: [
        {value: 'disabled', label: '無効'},
        {value: 'hidden',   label: '有効 (非表示)'},
        {value: 'enabled',  label: '有効'},
      ],
    }
  },

  computed: {
    apiUrl() {
      return '/_api/apps'
    },

    parentName() {
      return 'AppsTable'
    },

    defaultModel() {
      return {
        id:        '',
        entity_id: 'https://example.com/_sso/metadata',
        url:       'https://example.com/',
        name:      '',
        desc:      '',
        icon:      '',
        status:    'enabled',
        entity_data: JSON.stringify({
          'AssertionConsumerService':   'https://example.com/_sso/acs',
          'SingleLogoutService':        'https://example.com/_sso/sls',
          'NameIDFormat':               'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
          'simplesaml.nameidattribute': 'id',
        }, null, "\t"),
        role_maps:   [],
      }
    },
  },
}
</script>
