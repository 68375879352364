
<template lang="pug">
div.full-width
  q-tree(
    ref="tree"
    node-key="key"
    :nodes="nodes"
    :selected="[]"
    :expanded.sync="expanded"

    :tick-strategy="valueTicking ? 'strict' : 'none'"
    :ticked="valueTicking"
    @update:ticked="updateTicked"
  )
    template(v-slot:default-header="{node}")
      .roles-tree__click(
        v-if="isClickable"
        @dblclick="$emit('click-row', node)"
      )

      input.q-mr-xs.roles-tree__mapping(
        v-if="valueMapping && !node.noTick"
        :value="valueMapping[node.data.id]"
        @click.stop
        @input="updateMapping($event, node)"
      )

      .full-width.row.no-wrap.items-center
        .roles-tree__content
          q-icon.q-mr-sm(
            v-if="node.icon"
            size="xs"
            :name="node.icon"
          )

          span {{node.label}}
          span.roles-tree__full-key(v-if="isClickable || valueMapping") {{node.fullKey}}
          span.roles-tree__key(v-if="isClickable") {{node.data.id}}

        .col-auto.roles-tree__buttons
          x-info.q-mr-xs(
            v-if="node.data && node.data.desc"
            :text="node.data.desc"
          )

          q-icon(
            v-if="isClickable"
            name="more_vert"
            :id="'roles-tree__' + node.key"
            @click="$emit('click-menu', node)"
          )
</template>

<script>
import {isPlainObject, xor} from 'lodash-es'

import {createNamespacedHelpers} from 'vuex'
const sectionsTree = createNamespacedHelpers('sectionsTree')

import {makeQTreeNodesFromSectionsTree} from './roles.js'

export default {
  props: {
    noCache:   Boolean,
    lockRoles: [Array, Boolean],
    mapMode:   Boolean,
    value:     Array,
  },

  data() {
    return {
      expanded: [],
    }
  },

  computed: {
    ...sectionsTree.mapState(['sectionsTree']),
    ...sectionsTree.mapGetters(['roleMap']),

    isClickable() {
      return this.value == null
    },

    valueTicking() {
      return this.value != null && !this.mapMode ? this.value : null
    },

    valueMapping() {
      if(this.value == null || !this.mapMode) return null
      const valueMapping = {}
      for(const id of Object.keys(this.roleMap)) {
        valueMapping[id] = ''
      }
      for(const {id, key} of this.value) {
        valueMapping[id] = key
      }
      return valueMapping
    },

    nodes() {
      const nodes = makeQTreeNodesFromSectionsTree(this.sectionsTree)
      if(this.valueTicking) {
        return [{
          header: 'section',
          // icon: 'public',
          key: 'root',
          label: '権限',
          expandable: false,
          noTick: true,
          children: nodes,
        }]
      }
      return nodes
    },
  },

  watch: {
    nodes: {
      immediate: true,
      handler(value) {
        const expanded = []
        const walk = value => {
          for(const {header, key, children} of value ?? []) {
            if(header === 'section') {
              expanded.push(key)
            }
            walk(children)
          }
        }
        walk(value)
        this.expanded = expanded
      },
    },
  },

  async created() {
    await this.getSectionsTree(this.noCache)
  },

  methods: {
    ...sectionsTree.mapActions(['getSectionsTree']),

    updateTicked(value) {
      if(this.lockRoles === true) return
      if(Array.isArray(this.lockRoles)) {
        if(xor(value, this.value).some(diff => this.lockRoles.includes(this.roleMap[diff]?.fullKey))) return
      }
      value.sort()
      this.$emit('input', value)
    },

    updateMapping(ev, node) {
      const input = ev.target.value
      const id = node.data.id
      const map = {...this.valueMapping}
      if(input.length === 0) delete map[id]
      else map[id] = input
      this.$emit('input', Object.entries(map).filter(r => r[1] !== '').map(([id, key]) => ({id, key})))
    },
  },
}
</script>

<style lang="sass">
.roles-tree
  background-color: rgba(0, 0, 0, .05)
  border-radius: 4px

.roles-tree--dark
  background-color: rgba(255, 255, 255, .07)

.roles-tree__click
  height: 100%
  position: absolute
  width: calc(100% - 8px)

.roles-tree__mapping
  border: 1px solid #808080
  border-radius: 2px
  font-size: 12px
  width: 80px

.roles-tree__content
  flex: 1 1 0

.roles-tree__buttons
  line-height: 0

.roles-tree__key
  color: #80d080
  font-size: 11px
  padding-left: .5em

.roles-tree__full-key
  color: #8080d0
  font-size: 11px
  padding-left: .5em
</style>
