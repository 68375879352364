
<template lang="pug">
a-base-table(
  ref="root"
  v-bind="$attrs"
  :data="data_"
  :columns="columns"
  :visible-columns="visibleColumns"
  v-on="listeners"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )

  template(v-slot:pagination)
    span {{statusText}}
</template>

<script>
import {isEmpty} from 'lodash-es'
import {sortDate} from 'quasar/src/utils/sort.js'
import {isNumber,isDate} from 'quasar/src/utils/is.js'

import errorHandler from '@/aax/helpers/error-handler'

import ABaseTable from './ABaseTable.vue'

export default {
  name: 'a-eager-table',
  components: {ABaseTable},

  props: {
    data: Array,
    columns: Array,
    visibleColumns: Array,
  },

  data() {
    return {
      params: {},
    }
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        request: this.onRequest,
      }
    },

    cols() {
      // 取り扱うカラムの一覧を取得
      // project://vue/node_modules/quasar/src/components/table/table-column-selection.js
      let cols = this.columns

      if(cols == null) {
        const row = this.data[0]
        cols = row == null ? [] : Object.keys(row).map(name => ({
          name,
          field: name,
        }))
      }
      else {
        cols = cols.map(col => {
          col = {...col}
          if(col.field === undefined) {
            col.field = col.name
          }
          return col
        })
      }

      const visibleCols = this.visibleColumns
      if(visibleCols != null) {
        cols = cols.filter(col => col.required === true || visibleCols.includes(col.name))
      }

      return cols
    },

    data_() {
      let data = this.data ?? []

      const {ft, fc, fm, sc, so} = this.params

      // ft=<フィルタ文字列 未指定=フィルタしない>
      if(ft) {
        const texts = ft.toLowerCase().split(' ')

        // fc=<フィルタカラム 未指定=すべて選択>
        let cols = this.cols
        if(fc) {
          const col = cols.find(col => col.name === fc)
          if(col) {
            cols = [col]
          }
        }

        // fm=<フィルタメソッド m=match s=startswith e=endswith *=contains>
        let method
             if(fm === 'm') method = (h, n) => h === n
        else if(fm === 's') method = (h, n) => h.startsWith(n)
        else if(fm === 'e') method = (h, n) => h.endsWith(n)
        else                method = (h, n) => h.includes(n)

        //
        const cellValue = this.$refs.root.getQTable().getCellValue
        for(const text of texts) {
          data = data.filter(row => cols.some(col => method((cellValue(col, row) + '').toLowerCase(), text)))
        }
      }

      // sc=<ソートカラム 未指定=ソートしない>
      if(sc) {
        const col = this.cols.find(col => col.name === sc)
        if(col) {
          const val = typeof col.field === 'function' ? v => col.field(v) : v => v[col.field]

          // so=<ソート順序 d=desc *=asc>
          const dir = so === 'd' ? -1 : 1

          // project://vue/node_modules/quasar/src/components/table/table-sort.js
          data = [...data]
          data.sort((a, b) => {
            let
              A = val(a),
              B = val(b)

            if (A === null || A === void 0) {
              return -1 * dir
            }
            if (B === null || B === void 0) {
              return 1 * dir
            }
            if (col.sort !== void 0) {
              return col.sort(A, B, a, b) * dir
            }
            if (isNumber(A) === true && isNumber(B) === true) {
              return (A - B) * dir
            }
            if (isDate(A) === true && isDate(B) === true) {
              return sortDate(A, B) * dir
            }
            if (typeof A === 'boolean' && typeof B === 'boolean') {
              return (A - B) * dir
            }

            [A, B] = [A, B].map(s => (s + '').toLocaleString() )// .toLowerCase())

            return A < B
              ? -1 * dir
              : (A === B ? 0 : dir)
          })
        }
      }

      return data
    },

    statusText() {
      return this.data
        ? (this.data_.length < this.data.length
          ? this.data_.length + '行 (全' + this.data.length + '行中)'
          : '全' + this.data.length + '行'
        ) : ''
    },
  },

  methods: {
    async onRequest(params) {
      this.params = params
    },

    // 削除の確認ダイアログを表示する
    async dialogConfirm() {
      await this.$refs.root.dialogConfirm(...arguments)
    },
  },
}
</script>
