
import http from '@/aax/helpers/http'

export default {
  namespaced: true,

  state() {
    return {
      apps: null,
    }
  },

  mutations: {
    setApps(state, value) {state.apps = value},
  },

  actions: {
    async getApps({state, commit}, force = false) {
      if(force || state.apps === null) {
        commit('setApps', await http.$get('/_api/apps'))
      }
    },
  },
}
