
import {debounce} from 'lodash-es'

import {notifyWarning, notifyError} from './notify'

// error-reporter.jsで使用
// javascriptエラーレポート: 通知

const show = debounce(function(source, severity, args) {
  switch(severity) {
    case 'warning':
      notifyWarning([source, ...args])
      break

    default:
      notifyError([source, ...args])
      break
  }
}, 500)

function errorReporterNotify(source, severity, args) {
  show(source, severity, args)
}

//

export default errorReporterNotify
