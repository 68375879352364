import Vue from 'vue'

import './styles/quasar.sass'
import lang from 'quasar/lang/ja.js'
import '@quasar/extras/material-icons/material-icons.css'

import {
  Quasar,

  // -------------------------------------------------- components

  // QAjaxBar,
  // QAvatar,
  // QBadge,
  // QBanner,
  // QBar,
  // QBreadcrumbs,
  // QBreadcrumbsEl,
  QBtn,
  QBtnDropdown,
  // QBtnGroup,
  // QBtnToggle,
  QCard,
  QCardActions,
  QCardSection,
  // QCarousel,
  // QCarouselControl,
  // QCarouselSlide,
  // QChatMessage,
  // QCheckbox,
  // QChip,
  // QCircularProgress,
  // QColor,
  // QDate,
  QDialog,
  QDrawer,
  // QEditor,
  // QExpansionItem,
  // QFab,
  // QFabAction,
  QField,
  // QFile,
  // QFooter,
  QForm,
  QHeader,
  QIcon,
  // QImg,
  // QInfiniteScroll,
  // QInnerLoading,
  QInput,
  // QIntersection,
  QItem,
  QItemLabel,
  QItemSection,
  // QKnob,
  QLayout,
  // QLinearProgress,
  QList,
  // QMarkupTable,
  QMenu,
  // QNoSsr,
  // QOptionGroup,
  QPage,
  QPageContainer,
  // QPageScroller,
  // QPageSticky,
  // QPagination,
  // QParallax,
  // QPopupEdit,
  // QPopupProxy,
  // QPullToRefresh,
  // QRadio,
  // QRange,
  // QRating,
  QResizeObserver,
  // QResponsive,
  // QRouteTab,
  QScrollArea,
  // QScrollObserver,
  QSelect,
  // QSeparator,
  // QSkeleton,
  // QSlideItem,
  // QSlideTransition,
  // QSlider,
  QSpace,
  // QSpinner,
  // QSpinnerAudio,
  // QSpinnerBall,
  // QSpinnerBars,
  // QSpinnerComment,
  // QSpinnerCube,
  // QSpinnerDots,
  // QSpinnerFacebook,
  // QSpinnerGears,
  // QSpinnerGrid,
  // QSpinnerHearts,
  // QSpinnerHourglass,
  // QSpinnerInfinity,
  // QSpinnerIos,
  // QSpinnerOval,
  // QSpinnerPie,
  // QSpinnerPuff,
  // QSpinnerRadio,
  // QSpinnerRings,
  // QSpinnerTail,
  // QSplitter,
  // QStep,
  // QStepper,
  // QStepperNavigation,
  QTab,
  QTabPanel,
  QTabPanels,
  QTable,
  QTabs,
  QTd,
  // QTh,
  // QTime,
  // QTimeline,
  // QTimelineEntry,
  // QToggle,
  QToolbar,
  QToolbarTitle,
  QTooltip,
  QTr,
  QTree,
  // QUploader,
  // QUploaderAddTrigger,
  // QUploaderBase,
  // QVideo,
  // QVirtualScroll,

  // -------------------------------------------------- directives

  ClosePopup,
  // GoBack,
  // Intersection,
  // Mutation,
  Ripple,
  // Scroll,
  // ScrollFire,
  // TouchHold,
  // TouchPan,
  // TouchRepeat,
  // TouchSwipe,

  // ----------------------------------------------------- plugins

  // AddressbarColor,
  // AppFullscreen,
  // AppVisibility,
  // BottomSheet,
  // Cookies,
  // Dark,
  Dialog,
  Loading,
  // LoadingBar,
  // LocalStorage,
  // Meta,
  Notify,
  // Platform,
  // Screen,
  // SessionStorage,

} from 'quasar'

Vue.use(Quasar, {
  config: {},
  components: { /* not needed if importStrategy is not 'manual' */
    // QAjaxBar,
    // QAvatar,
    // QBadge,
    // QBanner,
    // QBar,
    // QBreadcrumbs,
    // QBreadcrumbsEl,
    QBtn,
    QBtnDropdown,
    // QBtnGroup,
    // QBtnToggle,
    QCard,
    QCardActions,
    QCardSection,
    // QCarousel,
    // QCarouselControl,
    // QCarouselSlide,
    // QChatMessage,
    // QCheckbox,
    // QChip,
    // QCircularProgress,
    // QColor,
    // QDate,
    QDialog,
    QDrawer,
    // QEditor,
    // QExpansionItem,
    // QFab,
    // QFabAction,
    QField,
    // QFile,
    // QFooter,
    QForm,
    QHeader,
    QIcon,
    // QImg,
    // QInfiniteScroll,
    // QInnerLoading,
    QInput,
    // QIntersection,
    QItem,
    QItemLabel,
    QItemSection,
    // QKnob,
    QLayout,
    // QLinearProgress,
    QList,
    // QMarkupTable,
    QMenu,
    // QNoSsr,
    // QOptionGroup,
    QPage,
    QPageContainer,
    // QPageScroller,
    // QPageSticky,
    // QPagination,
    // QParallax,
    // QPopupEdit,
    // QPopupProxy,
    // QPullToRefresh,
    // QRadio,
    // QRange,
    // QRating,
    QResizeObserver,
    // QResponsive,
    // QRouteTab,
    QScrollArea,
    // QScrollObserver,
    QSelect,
    // QSeparator,
    // QSkeleton,
    // QSlideItem,
    // QSlideTransition,
    // QSlider,
    QSpace,
    // QSpinner,
    // QSpinnerAudio,
    // QSpinnerBall,
    // QSpinnerBars,
    // QSpinnerComment,
    // QSpinnerCube,
    // QSpinnerDots,
    // QSpinnerFacebook,
    // QSpinnerGears,
    // QSpinnerGrid,
    // QSpinnerHearts,
    // QSpinnerHourglass,
    // QSpinnerInfinity,
    // QSpinnerIos,
    // QSpinnerOval,
    // QSpinnerPie,
    // QSpinnerPuff,
    // QSpinnerRadio,
    // QSpinnerRings,
    // QSpinnerTail,
    // QSplitter,
    // QStep,
    // QStepper,
    // QStepperNavigation,
    QTab,
    QTabPanel,
    QTabPanels,
    QTable,
    QTabs,
    QTd,
    // QTh,
    // QTime,
    // QTimeline,
    // QTimelineEntry,
    // QToggle,
    QToolbar,
    QToolbarTitle,
    QTooltip,
    QTr,
    QTree,
    // QUploader,
    // QUploaderAddTrigger,
    // QUploaderBase,
    // QVideo,
    // QVirtualScroll,
  },
  directives: { /* not needed if importStrategy is not 'manual' */
    ClosePopup,
    // GoBack,
    // Intersection,
    // Mutation,
    Ripple,
    // Scroll,
    // ScrollFire,
    // TouchHold,
    // TouchPan,
    // TouchRepeat,
    // TouchSwipe,
  },
  plugins: {
    // AddressbarColor,
    // AppFullscreen,
    // AppVisibility,
    // BottomSheet,
    // Cookies,
    // Dark,
    Dialog,
    Loading,
    // LoadingBar,
    // LocalStorage,
    // Meta,
    Notify,
    // Platform,
    // Screen,
    // SessionStorage,
  },
  lang: lang
 })
