
<template lang="pug">
.fit.centering-outer
  .centering-inner.text-center
    .text-h4.q-mx-xl 準備中です。
    .q-mx-xl.q-my-md しばらくお待ちいただいてから<br>再度アクセスをお試しください。
    //- .q-mx-xl(v-if="client_maint.message") {{client_maint.message}}
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

export default {
  data() {
    return {
      timer: null,
    }
  },

  computed: {
    ...aax.mapState(['client_maint']),
  },

  created() {
    this.beginTimer()
  },

  beforeDestroy() {
    this.killTimer()
  },

  methods: {
    ...aax.mapMutations(['setClientMaint']),
    ...aax.mapActions(['getServer']),

    beginTimer() {
      this.killTimer()
      if(this.client_maint.retryAfter) {
        this.timer = setTimeout(this.retry, this.client_maint.retryAfter * 1000)
      }
    },

    killTimer() {
      if(this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },

    async retry() {
      try {
        await this.getServer()
        this.setClientMaint(false)
      }
      catch(e) {}
      this.beginTimer()
    },
  },
}
</script>
