
<template lang="pug">
q-drawer(
  elevated overlay no-swipe-open no-swipe-close no-swipe-backdrop
  behavior="mobile"
  :value="value"
  :width="width"
  @input="show($event)"
  @click.native="show(false)"
)
  .full-height.column.no-wrap.overflow-hidden
    q-item(clickable v-ripple)
      q-item-section(avatar)
        q-icon(name="menu")
      q-item-section {{authName}}

    div(:class="separatorClass")

    aq-scroll-area.full-height
      q-item(
        v-for="route of routes"
        :key="route.name"
        clickable v-ripple
        :active="route.name === routeMetaName"
        @click="onClickRoute(route, $event)"
      )
        q-item-section(avatar)
          q-icon(:name="route.meta.icon")
        q-item-section {{route.meta.title}}

      slot(name="append")

    div(:class="separatorClass")

    //- RouterViewDrawerDisplaySettings

    slot(name="footer-append")
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

import {routes} from '@/router'

import AqScrollArea from '@/aax/components/AqScrollArea.vue'

// import RouterViewDrawerDisplaySettings from './RouterViewDrawerDisplaySettings.vue'

export default {
  // components: {AqScrollArea, RouterViewDrawerDisplaySettings},
  components: {AqScrollArea},

  props: {
    value: Boolean,
    width: {type: Number, default: 200},
  },

  computed: {
    ...aax.mapGetters(['authName', 'hasRole']),

    routes() {
      const routes_ = []
      for(const route of routes) {
        const {meta} = route
        if(meta?.title && this.hasRole(meta.roles)) {
          routes_.push(route)
        }
      }
      return routes_
    },

    routeMetaName() {
      return this.$route.meta?.parent ?? this.$route.name
    },

    separatorClass() {
      return this.$q.dark.isActive ? 'aax-drawer__separator--dark' : 'aax-drawer__separator'
    },
  },

  methods: {
    async onClickRoute(route, ev) {
      if(ev.shiftKey) {
        const {href} = this.$router.resolve({name: route.name})
        setTimeout(() => {
          window.open(href, '_blank')
        }, 0)
      }
      else {
        await this.$router.push({name: route.name})
      }
    },

    show(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="sass">
.aax-drawer__separator
  border-bottom: 1px solid black
  margin: 4px

.aax-drawer__separator--dark
  border-bottom: 1px solid white
  margin: 4px
</style>
