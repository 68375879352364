
<template lang="pug">
aq-page
  template(v-slot:header-title-after)
    .row.items-center.q-gutter-sm
      a-filter-input(v-model="tableState")

      q-btn(
        dense flat round
        icon="more_vert"
      )
        a-menu(:menu="menu")

  template(v-slot:default)
    a-lazy-table.full-height(
      ref="table"
      v-model="tableState"
      click-select enable-count
      api-url="/_api/idp_tokens"
      row-key="id"
      :columns="columns"
      selection="multiple"
      :selected.sync="selected"
    )
</template>

<script>
import {format, isValid, parseJSON} from 'date-fns'

import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

function dateFormat(val) {
  const date = parseJSON(val)
  return isValid(date) ? format(date, 'yyyy/MM/dd HH:mm:ss') : ''
}

export default {
  $http: true,

  data() {
    return {
      tableState: {},
      selected: [],
    }
  },

  computed: {
    ...aax.mapGetters(['asAdmin']),

    columns() {
      return [
        {
          name: 'name',
          align: 'left',
          sortable: true,
          label: '名前',
        },
        {
          name: 'apps.name',
          align: 'left',
          sortable: true,
          label: 'アプリ',
        },
        {
          name: 'last_used_at',
          align: 'left',
          sortable: true,
          label: '最終アクセス日時',
          format: dateFormat,
        },
        {
          name: 'created_at',
          align: 'left',
          sortable: true,
          label: '作成日時',
          format: dateFormat,
        },
      ]
    },

    menu() {
      return [
        {
          icon:     'add',
          label:    '新規作成',
          disabled: !this.asAdmin,
          click:    this.create,
        },
        {
          icon:     'delete',
          label:    '削除',
          disabled: !this.asAdmin || this.selected.length === 0,
          click:    this.destroy,
        },
      ]
    },
  },

  methods: {
    async create() {
      await this.$router.push({name: 'IdpTokensForm', params: {id: 'new'}})
    },

    async destroy() {
      await this.$refs.table.dialogConfirm('name')

      await this.$http.$post('/_api/idp_tokens/bulk-delete', this.selected.map(i => i.id))
      await this.$refs.table.reload()
    },
  },
}
</script>
