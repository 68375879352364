
<!-- 実験的コンポーネント -->

<template lang="pug">
q-menu(
  v-bind="$attrs"
  v-on="$listeners"
)
  a-menu-0(:menu="menu")
</template>

<script>
export default {
  name: 'a-menu',

  props: {
    menu: {type: Array, required: true},
  },
}
</script>
