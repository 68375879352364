
// 正しく動作させるにはrouterヘルパのnavigationDirectionDetectorの適用を必須

import {cloneDeep, isEqual} from 'lodash-es'

import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

import guardMixin from './guard'

export default {
  mixins: [guardMixin],
  $http: true,

  data() {
    return {
      original: {},

      // オーバーラップ表示状態 (null/ALoading/ANotfound)
      overwrap: null,

      // 入力項目データ
      model: {},

      // 入力項目エラー
      error: {},

      // 送信中状態
      sending: false,
    }
  },

  computed: {
    ...aax.mapState(['routeGuardActive']),

    // APIのURLを取得する [オーバーライド必須]
    apiUrl() {
      throw new Error('apiUrlを定義してください')
    },

    // routeのパラメーター名 [computed use only]
    keyPropName() {
      return 'id'
    },

    // formのキー [computed use only]
    key() {
      return this.$attrs[this.keyPropName]
    },

    // formのkeyを含むAPIのURL
    apiUrlWithKey() {
      return this.apiUrl + '/' + this.key
    },

    // 表示プロパティ名 [computed use only]
    displayPropName() {
      return 'name'
    },

    // 表示名
    displayName() {
      return this.original[this.displayPropName]
    },

    // 新規作成ならtrue
    isNew() {
      return this.key === 'new'
    },

    // 変更箇所
    changes() {
      const changes = {}
      for(const [key, value] of Object.entries(this.model)) {
        if(!isEqual(this.original[key], value)) {
          changes[key] = value
        }
      }
      return changes
    },

    // 変更されていればtrue
    isChanged() {
      return Object.keys(this.changes).length != 0
    },

    // ナビゲーションをガードするか？
    guardIsActive() {
      return this.isChanged
    },

    // 戻り先のroute名 [オーバーライド必須] [computed use only]
    parentName() {
      throw new Error('parentNameを定義してください')
    },

    // 戻り先のpushパラメーター
    parentLocation() {
      return {name: this.parentName, params: this.$route.params}
    },

    // 入力項目データ初期値
    defaultModel() {
      return {}
    },
  },

  async created() {
    await this.get()
  },

  methods: {
    async get() {
      if(this.isNew) {
        this.model = cloneDeep(this.defaultModel)
      }
      else {
        this.overwrap = 'ALoading'
        try {
          this.model = {
            ...cloneDeep(this.defaultModel),
            ...this.loadModifier(await this.$http.$get(this.apiUrlWithKey)),
          }
          this.overwrap = null
        }
        catch(e) {
          if(!e.response || e.response.status !== 404) {
            throw e
          }
          this.overwrap = 'ANotfound'
        }
      }
      this.original = cloneDeep(this.model)
    },

    // 新規作成する
    async create() {
      if(this.sending) {
        return
      }

      this.sending = true
      try {
        await this.$http.post(this.apiUrl, this.saveModifier(this.model))
        this.original = cloneDeep(this.model)
        await this.toParent()
      }
      catch(e) {
        this.handleFormError(e)
      }
      finally {
        this.sending = false
      }
    },

    // 更新する
    async update() {
      if(this.sending) {
        return
      }

      if(!this.isChanged) {
        await this.toParent()
        return
      }

      this.sending = true
      try {
        await this.$http.put(this.apiUrlWithKey, this.saveModifier(this.changes))
        this.original = cloneDeep(this.model)
        await this.toParent()
      }
      catch(e) {
        this.handleFormError(e)
      }
      finally {
        this.sending = false
      }
    },

    handleFormError(e) {
      if(!e.response || !e.response.data || !e.response.data.errors || e.response.status !== 422) {
        throw e
      }
      let focus = true
      this.error = Object.entries(e.response.data.errors).reduce((error, [key, value]) => {
        const dotIndex = key.indexOf('.')
        if(dotIndex !== -1) {
          key = key.substring(0, dotIndex)
        }
        if(!error[key]) {
          error[key] = value[0]
          if(focus) {
            const ref = this.$refs[key]
            if(ref) {
              for(const method of ['focus', 'select', 'showPopup']) {
                if(ref.hasOwnProperty(method)) {
                  ref[method]()
                  focus = false
                  break
                }
              }
            }
          }
        }
        return error
      }, {})
    },

    // 削除する
    async destroy() {
      if(this.sending) {
        return
      }

      await this.$dialogConfirm('「' + this.displayName + '」を削除します', 'この操作を取り消すことはできません。よろしいですか？')
      this.sending = true
      try {
        await this.$http.delete(this.apiUrlWithKey)
        this.original = cloneDeep(this.model)
        await this.toParent()
      }
      finally {
        this.sending = false
      }
    },

    // 戻る (更新)
    async toParent() {
      await this.$router.push(this.parentLocation)
    },

    // 戻る (キャンセル)
    async back() {
      this.$router.back()
    },

    // 読込時のデータ変更
    loadModifier(data) {
      return data
    },

    // 保存時のデータ変更
    saveModifier(data) {
      // 更新時は変化がないプロパティは存在しないことに注意すること
      return data
    },
  },
}
