
function sleep(milliseconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, milliseconds)
  })
}

// プラグイン

const sleepPlugin = {
  install(Vue, _options) {
    Vue.prototype.$sleep = sleep
  },
}

//

export default sleep

export {
  sleepPlugin,
}
