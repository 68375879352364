
<template lang="pug">
UsersForm(
  ref="root"
  my-profile
  :id="authId"
)
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

import UsersForm from './UsersForm.vue'

export default {
  components: {UsersForm},

  computed: {
    ...aax.mapGetters(['authId']),
  },

  // ナビゲーションガード
  async beforeRouteUpdate(to, from, next) {
    await this.$refs.root.guardLeave(to, from, next)
  },

  async beforeRouteLeave(to, from, next) {
    await this.$refs.root.guardLeave(to, from, next)
  },
}
</script>
