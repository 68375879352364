
<template lang="pug">
q-dialog(
  ref="dialog"
  full-width persistent
  @hide="onDialogHide"
)
  q-card.q-px-sm.q-pb-sm.token-dialog__card
    q-card-section
      .text-h6 {{title}}

    q-item.q-pb-lg
      .token-dialog__text.full-width {{token}}

    q-card-section.q-pt-none(v-if="desc") {{desc}}

    q-card-actions.full-width(align="between")
      aq-btn(
        label="閉じる"
        @click="hide"
      )

      aq-btn(
        color="primary"
        icon="content_copy"
        label="クリップボードにコピーする"
        @click="onClickCopy"
      )
</template>

<script>
import copyToClipboard from 'copy-to-clipboard'

export default {
  props: {
    title: String,
    token: String,
    desc:  String,
  },

  methods: {
    show() {
      this.$refs.dialog.show()
    },

    hide() {
      this.$emit('ok')
      this.$refs.dialog.hide()
    },

    onDialogHide() {
      this.$emit('hide')
    },

    onClickCopy() {
      if(copyToClipboard(this.token, {message: '#{key}を押してコピーしてください'})) {
        this.$notifySuccess('クリップボードにコピーしました')
      }
    },
  },
}
</script>

<style lang="sass">
.token-dialog__card
  user-select: none

.token-dialog__text
  border: 1px solid lightgray
  border-radius: 4px
  padding: 9px 12px 8px
  user-select: text
  word-break: break-all
</style>
