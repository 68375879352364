
import http from '@/aax/helpers/http'
import aax from '@/aax/store'

export default {
  namespaced: true,

  state() {
    return {
      ...aax.state(),

      // サーバー拡張情報
      //
    }
  },

  getters: {
    ...aax.getters,
  },

  mutations: {
    ...aax.mutations,

    // サーバー拡張情報
    //
  },

  actions: {
    ...aax.actions,

    async getServer({dispatch}) {
      dispatch('setServer', await http.$get('/_api/server'))

      // サーバー拡張情報
      //
    },
  },
}
