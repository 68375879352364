
import Axios from 'axios'
import {format} from 'date-fns'
import StackTrace from 'stacktrace-js'

// error-reporter.jsで使用
// javascriptエラーレポート: サーバー通知

async function errorReporterPost(source, severity, args) {
  const time = format(Date.now(), 'yyyy-MM-dd HH:mm:ss')

  args = await Promise.all(args.map(async arg => {
    if(arg instanceof Error) {
      try {
        return {
          string: arg.toString(),
          trace: await StackTrace.fromError(arg),
        }
      }
      catch(e) {}
    }
    return arg
  }))

  try {
    await Axios.post('/_api/error-report', {time, url: window.location.href, source, args}, {
      headers: {'X-Requested-With': 'XMLHttpRequest'},
    })
  }
  catch(e) {}
}

//

export default errorReporterPost
