
<template lang="pug">
q-icon.q-mr-xs(
  name="info"
  @mouseenter="show = true"
  @mouseleave="show = false"
)
  q-menu(
    v-model="show"
    no-parent-event no-focus no-refocus persistent
    max-width="80%"
    self="top right"
  )
    .q-px-sm.q-py-xs(v-html="html")
</template>

<script>
import {escape} from 'html-escaper'

export default {
  props: {
    text: String,
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    html() {
      return escape(this.text).replace(/\n/g, '<br>')
    },
  },
}
</script>
