
<template lang="pug">
aq-page(:title-after="original.name" elevated)
  template(v-slot:header-title-before)
    q-btn(
      dense round unelevated
      icon="arrow_back"
      @click="back"
    )

  template(
    v-slot:header-title-after
    v-if="!overwrap"
  )
    .row.items-center.q-gutter-sm
      aq-btn(
        v-if="isNew"
        color="primary"
        icon="add"
        label="作成"
        @click="create"
      )

      aq-btn(
        v-else
        color="primary"
        icon="check"
        label="更新"
        @click="update"
      )

  template(v-slot:default)
    component(
      v-if="overwrap"
      :is="overwrap"
    )

    aq-scroll-area.full-height(v-else)
      .q-ma-md
        q-card.q-mb-md.q-pl-md.q-pt-md
          .row
            .col-12.col-md-6.col-lg-4.q-pr-md.q-pb-md
              aq-select(
                ref="app"
                v-model="model.app_id"
                emit-value map-options
                label="アプリ"
                :options="appOpts"
                :error="error.app_id"
              )

            .col-12.col-md-6.col-lg-4.q-pr-md.q-pb-md
              aq-input(
                ref="name"
                v-model.trim="model.name"
                label="名前"
                maxlength="250"
                :error="error.name"
              )
</template>

<script>
import {cloneDeep} from 'lodash-es'

import FormMixin from '@/aax/helpers/form'

import TokenDialogContent from '@/helpers/TokenDialogContent.vue'

export default {
  mixins: [FormMixin],

  data() {
    return {
      apps: [],
    }
  },

  computed: {
    apiUrl() {
      return '/_api/idp_tokens'
    },

    parentName() {
      return 'IdpTokensTable'
    },

    defaultModel() {
      return {
        name:   '',
        app_id: '',
      }
    },

    appOpts() {
      return this.apps.map(app => ({value: app.id, label: app.name}))
    },
  },

  async created() {
    this.apps = await this.$http.$get('/_api/apps')
  },

  methods: {
    async create() {
      if(this.sending) {
        return
      }

      this.sending = true
      try {
        const result = await this.$http.$post(this.apiUrl, this.saveModifier(this.model))
        this.original = cloneDeep(this.model)

        await this.$dialog({
          component: TokenDialogContent,
          parent: this,
          title: '「' + this.model.name + '」のAPIトークン',
          token: result.hash,
          desc:  'このダイアログを閉じるとトークンは2度と確認できません。安全な場所に書き留めた上で閉じるボタンを押してください。',
        })

        await this.toParent()
      }
      catch(e) {
        this.handleFormError(e)
      }
      finally {
        this.sending = false
      }
    },
  },
}
</script>
