
<template lang="pug">
aq-page(:title="title" elevated)
  template(v-slot:header-title-before)
    q-btn(
      dense round unelevated
      icon="arrow_back"
      @click="back"
    )

  template(
    v-slot:header-title-after
    v-if="!overwrap"
  )
    .row.items-center.q-gutter-sm
      aq-btn(
        v-if="isNew"
        color="primary"
        icon="add"
        label="作成"
        @click="create"
      )

      aq-btn(
        v-else
        color="primary"
        icon="check"
        label="更新"
        @click="update"
      )

  template(v-slot:default)
    component(
      v-if="overwrap"
      :is="overwrap"
    )

    aq-scroll-area.full-height(v-else)
      .q-ma-md
        q-card.q-mb-md.q-pl-md.q-pt-md
          .row
            .col-12.col-md-6.col-lg-4.q-pr-md.q-pb-md
              aq-input(
                ref="id"
                v-model.trim="model.id"
                label="UUID"
                maxlength="250"
                mask="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                :readonly="!isNew"
                :error="error.id"
              )

            .col-12.col-md-6.col-lg-4.q-pr-md.q-pb-md
              aq-input(
                ref="key"
                v-model.trim="model.key"
                autofocus
                label="識別子"
                maxlength="250"
                :error="error.key"
              )

            .col-12.col-md-6.col-lg-4.q-pr-md.q-pb-md
              aq-input(
                ref="name"
                v-model.trim="model.name"
                label="名前"
                maxlength="250"
                :error="error.name"
              )

            .col-12.col-md-6.col-lg-4.q-pr-md.q-pb-md
              a-textarea(
                ref="desc"
                v-model.trim="model.desc"
                autogrow handle-tab
                label="説明"
                maxlength="65000"
                :error="error.desc"
              )
</template>

<script>
import FormMixin from '@/aax/helpers/form'

export default {
  mixins: [FormMixin],

  computed: {
    title() {
      let title = 'セクション'
      if(this.original.name) {
        title += ' / ' + this.original.name
      }
      return title
    },

    apiUrl() {
      return '/_api/sections'
    },

    parentName() {
      return 'RolesTree'
    },

    defaultModel() {
      return {
        id:   '',
        key:  '',
        name: '',
        desc: '',
      }
    },
  },
}
</script>
