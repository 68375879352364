
import {escape, isString} from 'lodash-es'
import {Notify} from 'quasar'

// 通知
// Quasar Framework実装

function notify(...args) {
  const texts = []
  const opts = {
    actions: [{icon: 'close', color: 'white'}],
    html: true,
    timeout: 3000,
    position: 'bottom-right',
  }

  for(const arg of args) {
    if(isString(arg) || Array.isArray(arg)) {
      texts.push(arg)
    }
    else {
      Object.assign(opts, arg)
    }
  }

  if(0 < texts.length) {
    Object.assign(opts, {message: texts[0]})
  }

  if(opts.html && !opts.noEscape) {
    if(opts.message) {
      opts.message = (Array.isArray(opts.message) ? opts.message : opts.message.split("\n")).map(escape).join('<br>')
    }

    if(opts.caption) {
      opts.caption = (Array.isArray(opts.caption) ? opts.caption : opts.caption.split("\n")).map(escape).join('<br>')
    }
  }

  return Notify.create(opts)
}

// Primary通知

function notifySuccess(...args) {
  return notify({
    type: 'positive',
  }, ...args)
}

// Info通知

function notifyInfo(...args) {
  return notify({
    type: 'info',
  }, ...args)
}

// Warning通知

function notifyWarning(...args) {
  return notify({
    type: 'warning',
    actions: [{icon: 'close', color: 'black'}],
  }, ...args)
}

// Error通知

function notifyError(...args) {
  return notify({
    type: 'negative',
  }, ...args)
}

// プラグイン

const notifyPlugin = {
  install(Vue, _options) {
    Vue.prototype.$notify        = notify
    Vue.prototype.$notifySuccess = notifySuccess
    Vue.prototype.$notifyInfo    = notifyInfo
    Vue.prototype.$notifyWarning = notifyWarning
    Vue.prototype.$notifyError   = notifyError
  },
}

//

export default notify

export {
  notifyPlugin,
  notifySuccess,
  notifyInfo,
  notifyWarning,
  notifyError,
}
