
import http from '@/aax/helpers/http'
import {makeRoleMapFromSectionsTree} from '@/components/roles'

export default {
  namespaced: true,

  state() {
    return {
      sectionsTree: null,
    }
  },

  getters: {
    roleMap: state => makeRoleMapFromSectionsTree(state.sectionsTree)
  },

  mutations: {
    setSectionsTree(state, value) {state.sectionsTree = value},
  },

  actions: {
    async getSectionsTree({state, commit}, force = false) {
      if(force || state.sectionsTree === null) {
        commit('setSectionsTree', await http.$get('/_api/sections/tree'))
      }
    },
  },
}
