
<template lang="pug">
q-select(
  ref="root"
  v-bind="attrs"
  :error="Boolean(error)"
  :error-message="error"
  v-on="$listeners"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

export default {
  name: 'aq-select',

  inject: {
    inToolbar: {default: false},
  },

  props: {
    error: String,
  },

  computed: {
    ...aax.mapGetters(['getThemeAttrs']),

    attrs() {
      const attrs = this.getThemeAttrs(this.$attrs.dark)

      return {
        ...(this.inToolbar && attrs.toolbarField ? attrs.toolbarField : attrs.field),
        ...(this.inToolbar && attrs.toolbarSelect ? attrs.toolbarSelect : attrs.select),
        ...this.$attrs,
      }
    },
  },

  methods: {
    ...[
      'scrollTo',
      'reset',
      'refresh',
      'resetValidation',
      'validate',
      'focus',
      'showPopup',
      'hidePopup',
      'removeAtIndex',
      'add',
      'toggleOption',
      'setOptionIndex',
      'moveOptionSelection',
      'filter',
      'updateMenuPosition',
      'updateInputValue',
      'isOptionSelected',
      'getEmittingOptionValue',
      'getOptionValue',
      'getOptionLabel',
      'isOptionDisabled',
    ].reduce((s, n) => ({...s, [n]() {return this.$refs.root[n](...arguments)}}), {}),
  },
}
</script>
