
import {cloneDeep, flattenDeep, isEmpty, merge} from 'lodash-es'
import {Dark} from 'quasar'

import http from '@/aax/helpers/http'
import defaultTheme from './defaultTheme'

//------------------------------------------------------------------------------
// グローバル状態

const globalMixin = {
  state() {
    return {
      client_maint: false,
    }
  },

  mutations: {
    setClientMaint(state, value) {state.client_maint = value},
  },
}

//------------------------------------------------------------------------------
// サーバー情報

const serverMixin = {
  state() {
    return {
      auth:          null,
      debug:         null,
      env:           null,
      error_report:  null,
      home_url:      null,
      server_maint:  null,
      version:       null,

      routeGuardActive: true,
    }
  },

  getters: {
    isLocal:        state => state.env === 'local',
    isProduction:   state => state.env === 'production',

    isUnauthorized: state => state.auth != null && isEmpty(state.auth),
    isAuthorized:   state => state.auth != null && !isEmpty(state.auth),

    authId:         state => state.auth?.id,
    authName:       state => state.auth?.name,
    authRoles:      state => state.auth?.roles,

    hasRole: (_, getters) => (...roles) => {
      if(getters.isUnauthorized) return true                      // 非ログイン状態なら、true
      if(isEmpty(getters.authRoles)) return false                 // ログイン権限が1つもなければ、false
      roles = flattenDeep(roles).filter(x => x != null)
      if(roles.length === 0) return true                          // 引数の条件が1つもなければ、true
      return getters.authRoles.some(role => roles.includes(role)) // ログイン権限と引数の条件に一致するものがあれば、true
    },

    asDev:   (_, getters) => getters.hasRole('dev'),
    asAdmin: (_, getters) => getters.hasRole('dev', 'admin'),
  },

  mutations: {
    setAuth         (state, value) {state.auth          = value},
    setDebug        (state, value) {state.debug         = value},
    setEnv          (state, value) {state.env           = value},
    setErrorReport  (state, value) {state.error_report  = value},
    setHomeUrl      (state, value) {state.home_url      = value},
    setServerMaint  (state, value) {state.server_maint  = value},
    setVersion      (state, value) {state.version       = value},

    setRouteGuardActive(state, value) {state.routeGuardActive = value},
  },

  actions: {
    async getServer({dispatch}) {
      dispatch('setServer', await http.$get('/_api/server'))
    },

    setServer({commit}, data) {
      commit('setAuth',          data.auth)
      commit('setDebug',         data.debug)
      commit('setEnv',           data.env)
      commit('setErrorReport',   data.error_report)
      commit('setHomeUrl',       data.home_url)
      commit('setServerMaint',   data.server_maint)
      commit('setVersion',       data.version)

      commit('setRouteGuardActive', true)
    },
  },
}

//------------------------------------------------------------------------------
// テーマ

const themeMixin = {
  state() {
    return {
      theme: defaultTheme,
    }
  },

  getters: {
    themeColors(state, getters) {
      const colors = cloneDeep(state.theme.colors.production)

      if(!getters.isProduction) {
        merge(colors, state.theme.colors.development)
      }

      colors.dark = merge({}, colors.light, colors.dark)

      return colors
    },

    themeAttrsLight(state, getters) {
      return state.theme.attrs(getters.themeColors.light)
    },

    themeAttrsDark(state, getters) {
      return state.theme.attrs(getters.themeColors.dark)
    },

    getThemeAttrs(_, getters) {
      return isDark => (isDark ?? Dark.isActive) ? getters.themeAttrsDark : getters.themeAttrsLight
    },
  },

  mutations: {
    setTheme(state, value) {
      state.themes = value
    },
  },
}

//------------------------------------------------------------------------------

function mixin(entries) {
  return {
    state: () => Object.assign({}, ...entries.map(e => e.state ? e.state() : {})),
    getters:     Object.assign({}, ...entries.map(e => e.getters)),
    mutations:   Object.assign({}, ...entries.map(e => e.mutations)),
    actions:     Object.assign({}, ...entries.map(e => e.actions)),
  }
}

export default {
  namespaced: true,

  ...mixin([
    globalMixin,
    serverMixin,
    themeMixin,
  ]),
}
