
import http from '@/aax/helpers/http'

async function get() {
  return (await http.$get('/_api/master/stores')).map(store => {
    return {
      ...store,

      get label() {
        return '(' + this.id + ') ' + this.name
      },
    }
  })
}

function keyById(stores) {
  const storeMap = {}
  for(const store of stores) {
    storeMap[store.id] = store
  }
  return storeMap
}

async function getKeyByCode() {
  return keyById(await get())
}

const storesApi = {
  get,
  keyById,
  getKeyByCode,
}

export default storesApi
