
<template lang="pug">
aq-input(
  v-bind="$attrs"
  type="textarea"
  :value="value"
  v-on="$listeners"
  @keydown.tab="onKeydownTab"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )
</template>

<script>
// required yarn add --dev indent-textarea
import * as indentation from 'indent-textarea'

export default {
  name: 'a-textarea',

  props: {
    value:     String,
    handleTab: Boolean,
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
  },

  methods: {
    async onKeydownTab(ev) {
      if(this.handleTab) {
        if(ev.getModifierState('Shift')) {
          indentation.unindent(ev.target)
        }
        else {
          indentation.indent(ev.target)
        }
        ev.preventDefault()
      }
    },
  },
}
</script>
