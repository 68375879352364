
<template lang="pug">
aq-page(elevated)
  template(v-slot:header-title-after)
    q-btn#roles-tree__menu(
      dense flat round
      icon="more_vert"
      @click="clickMenu"
    )

  template(v-slot:default)
    aq-scroll-area.full-height
      x-roles-tree.q-pa-md(
        no-cache
        @click-row="clickRow"
        @click-menu="clickMenu"
      )

    q-menu(
      v-model="menuShow"
      no-parent-event
      :target="menuTarget"
    )
      q-list
        a-dropdown-item(
          v-for="{disabled, icon, label, on} of menuList"
          :disabled="disabled"
          :icon="icon"
          :key="label"
          @click="on"
        ) {{label}}
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const sectionsTree = createNamespacedHelpers('sectionsTree')

import {isEditableSection, isEditableRole} from '@/components/roles'

export default {
  $http: true,

  data() {
    return {
      menuTarget: true,
      menuList: [],
      menuShow: false,
    }
  },

  methods: {
    ...sectionsTree.mapActions(['getSectionsTree']),

    async clickRow({header, data}) {
      switch(header) {
        case 'section':
          await this.editSection(data)
          break

        case 'role':
          await this.editRole(data)
          break
      }
    },

    async clickMenu({header, key, data}) {
      this.menuTarget = '#roles-tree__' + (key ?? 'menu')

      switch(header) {
        default:
          this.menuList = [
            {
              icon: 'create_new_folder',
              label: 'セクションを新規作成',
              on: () => this.newSection(),
            },
          ]
          this.menuShow = !this.menuShow
          break

        case 'section':
          this.menuList = [
            {
              icon: 'add',
              label: '権限を新規作成',
              on: () => this.newRole(data),
            },
            {
              disabled: !isEditableSection(data.key),
              icon: 'delete',
              label: '削除',
              on: () => this.deleteSection(data),
            },
          ]
          this.menuShow = true
          break

        case 'role':
          this.menuList = [
            {
              disabled: !isEditableRole(data.key, data.section?.key),
              icon: 'delete',
              label: '削除',
              on: () => this.deleteRole(data),
            },
          ]
          this.menuShow = true
          break
      }
    },

    async newSection() {
      this.$router.push({name: 'SectionsForm', params: {id: 'new'}})
    },

    async editSection(section) {
      if(isEditableSection(section.key)) {
        this.$router.push({name: 'SectionsForm', params: {id: section.id}})
      }
    },

    async deleteSection(section) {
      if(isEditableSection(section.key)) {
        await this.$dialogConfirm('「' + section.name + '」を削除します', '割り当てられている権限はすべて削除されます。権限の削除は関連付けられているアプリケーションの動作に重大な影響を及ぼします。この操作を取り消すことはできません。よろしいですか？')
        await this.$http.delete('/_api/sections/' + section.id)
        await this.getSectionsTree(true)
      }
    },

    async newRole(section) {
      this.$router.push({name: 'RolesFormNew', params: {id: 'new', section_id: section.id}})
    },

    async editRole(role) {
      if(isEditableRole(role.key, role.section?.key)) {
        this.$router.push({name: 'RolesForm', params: {id: role.id}})
      }
    },

    async deleteRole(role) {
      if(isEditableRole(role.key, role.section?.key)) {
        await this.$dialogConfirm('「' + role.section?.name + ' / ' + role.name + '」を削除します', 'ユーザーに割り当てられている権限はすべて解除されます。権限の削除は関連付けられているアプリケーションの動作に重大な影響を及ぼします。この操作を取り消すことはできません。よろしいですか？')
        await this.$http.delete('/_api/roles/' + role.id)
        await this.getSectionsTree(true)
      }
    },
  },
}
</script>
