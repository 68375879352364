
<template lang="pug">
.absolute-center ロードしています
</template>

<script>
export default {
  name: 'a-loading',
}
</script>
