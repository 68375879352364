<template lang="pug">
.relative-position(:style="sizeStyle")
  img.fit(
    v-if="src"
    :src="imageUrl"
    :style="edgeRadiusStyle"
  )

  .x-avatar__no-image-container(
    v-else
    :style="edgeRadiusStyle"
  )
    q-icon.absolute-center(
      color="grey-4"
      name="image"
      :size="iconSizeStyle"
    )
</template>

<script>
export default {
  name: 'x-avatar',

  props: {
    src: String,
    width:  {type: [String, Number], default: 100},
    height: {type: [String, Number], default: 100},
    edgeRadius: String,
  },

  computed: {
    sizeStyle() {
      return {
        width:  this.width  + 'px',
        height: this.height + 'px',
      }
    },

    edgeRadiusStyle() {
      return {
        borderRadius: this.edgeRadius,
      }
    },

    iconSizeStyle() {
      return Math.min(this.width, this.height) * .5 + 'px'
    },

    imageUrl() {
      return process.env.VUE_APP_PATH && this.src
        ? process.env.VUE_APP_PATH.replace(/\/+$/, '') + '/' + this.src.replace(/^\/+/, '')
        : this.src
    },
  },
}
</script>

<style lang="sass">
.x-avatar__no-image-container
  border: 1px solid lightgray
  height: 100%
  width: 100%
</style>
