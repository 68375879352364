
<template lang="pug">
aq-page
  template(v-slot:header-title)
    slot

    .row.items-center.q-gutter-sm
      a-filter-input(v-model="tableState")

  template(v-slot:default)
    a-lazy-table.full-height(
      v-if="isLazy"
      v-model="tableState"
      api-url="/_api/test/dummy"
      row-key="id"
      :columns="columns"
      :selected="selected"
      @row-click="$notify('row-click')"
      @update:selected="_updateSelected"
    )

    a-eager-table.full-height(
      v-else
      v-model="tableState"
      row-key="id"
      :data="data"
      :columns="columns"
      :selected="selected"
      @row-click="$notify('row-click')"
      @update:selected="_updateSelected"
    )
</template>

<script>
import AFilterInput from '@/aax/components/AFilterInput.vue'
import ALazyTable   from '@/aax/components/ALazyTable.vue'

export default {
  components: {AFilterInput, ALazyTable},
  $http: true,

  data() {
    return {
      isLazy: true,

      httpStatus: 'stop',

      tableState: {},

      selected: [],

      data: [],
    }
  },

  computed: {
    columns() {
      return [
        {name: 'id',       field: 'id',       align: 'left', sortable: true, label: 'ID'},
        {name: 'name',     field: 'name',     align: 'left', sortable: true, label: '氏名'},
        {name: 'email',    field: 'email',    align: 'left', sortable: true, label: 'メール'},
        {name: 'address',  field: 'address',  align: 'left', sortable: true, label: '住所'},
        {name: 'tel',      field: 'tel',      align: 'left', sortable: true, label: '電話'},
        {name: 'birthday', field: 'birthday', align: 'left', sortable: true, label: '誕生日'},
      ]
    },
  },

  async created() {
    if(!this.isLazy) {
      this.data = await this.$http.$get('/_api/test/dummy')
    }
  },

  methods: {
    async onHttpStart() {
      this.httpStatus = 'start'

      const results = await Promise.all([
        this.$http.get('/_api/slow/1', {showLoading: true}),
        this.$http.get('/_api/slow/2', {}),
        this.$http.get('/_api/slow/3', {}),
      ])

      console.log('results', results) // eslint-disable-line no-console

      this.httpStatus = 'completed'
    },

    async onHttpStop() {
      this.httpStatus = 'stop'

      this.$http.$cancel()

    },

    _updateSelected(value) {
      console.log('_updateSelected', value.length) // eslint-disable-line no-console
      this.selected = value
    },
  },
}
</script>
