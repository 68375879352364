
// 正しく動作させるにはrouterヘルパのnavigationDirectionDetectorの適用を必須

import {navigationDirectionIsBack, navigationDirectionIsForward} from '@/aax/helpers/router'

export default {

  // guardIsActive を data または computed で定義すること

  async created() {
    window.addEventListener('beforeunload', this.guardBeforeUnload)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.guardBeforeUnload)
  },

  async beforeRouteUpdate(to, from, next) {
    await this.guardLeave(to, from, next)
  },

  async beforeRouteLeave(to, from, next) {
    await this.guardLeave(to, from, next)
  },

  methods: {
    guardBeforeUnload(e) {
      if(this.guardIsActive && !this.guardIsDisabled) {
        e.preventDefault()
        e.returnValue = ''
      }
    },

    async guardLeave(to, from, next) {
      if(this.$router.$lock.count) {
        next(false)
        return
      }

      if(!this.guardIsActive || to.path === from.path || this.guardIsDisabled) {
        next()
        return
      }

      next(false)

      try {
        await this.guardDialog()
      }
      catch(e) {
        if(e.name === 'Error' && e.message === '') {
          return
        }
        throw e
      }

      this.guardIsDisabled = true

      if(navigationDirectionIsBack()) {
        this.$router.back()
      }
      else if(navigationDirectionIsForward()) {
        this.$router.forward()
      }
      else {
        // KNOWNISSUE: replaceを使用している場合は、検出できていない
        this.$router.push(to)
      }
    },

    async guardDialog() {
      return await this.$dialogConfirm('ページを移動しようとしています', '続けると変更箇所が失われます。よろしいですか？')
    },
  },
}
