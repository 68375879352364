
<!-- 実験的コンポーネント -->

<template lang="pug">
q-list
  a-menu-1(
    v-for="(item, index) of menu_"
    :key="index"
    v-bind="item"
  )
</template>

<script>
export default {
  name: 'a-menu-0',

  props: {
    menu: {type: Array, required: true},
  },

  computed: {
    menu_() {
      return this.menu.filter(x => !x.hidden)
    },
  },
}
</script>
