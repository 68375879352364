
<template lang="pug">
aq-page
  template(v-slot:header-title-after)
    .row.items-center.q-gutter-sm
      a-filter-input(v-model="tableState")

      q-btn(
        dense flat round
        icon="more_vert"
      )
        a-menu(:menu="menu")

  template(v-slot:default)
    a-lazy-table.full-height(
      ref="table"
      v-model="tableState"
      click-select enable-count
      api-url="/_api/users"
      row-key="id"
      :columns="columns"
      :selected.sync="selected"
      selection="multiple"
      @row-dblclick="update"
    )
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')
const sectionsTree = createNamespacedHelpers('sectionsTree')
const auths = createNamespacedHelpers('auths')

// キョーエイ特別処置: 店舗テーブル --------------------------------------------
import storesApi from '@/api/storesApi'
//------------------------------------------------------------------------------

function formatMap(getter) {
  return values => {
    if(values.length === 0) return 'なし'
    let text = values.slice(0, 3).map(getter).join(', ')
    if(3 < values.length) text += ', ...'
    return text
  }
}

export default {
  $http: true,

  data() {
    return {
      tableState: {},
      selected: [],

      // キョーエイ特別処置: 店舗テーブル --------------------------------------------
      storesByCode: null,
      //------------------------------------------------------------------------------
    }
  },

  computed: {
    ...aax.mapGetters(['authId', 'asAdmin']),
    ...sectionsTree.mapGetters(['roleMap']),
    ...auths.mapState(['auths']),
    ...auths.mapGetters(['authMap']),

    columns() {
      const cols = [
        {
          name: 'email',
          align: 'left',
          sortable: true,
          label: 'メールアドレス',
        },
        {
          name: 'name',
          align: 'left',
          sortable: true,
          label: '名前',
          format: (v, {id}) => id === this.authId ? v + '【自分】' : v,
        },

        // キョーエイ特別処置: ユーザー属性 --------------------------------------------
        {
          name: 'empl_code',
          align: 'left',
          // sortable: true,
          label: '社員コード',
        },
        {
          name: 'store_code',
          align: 'left',
          // sortable: true,
          label: '店舗',
          format: store_code => {
            if(store_code && this.storesByCode != null) {
              const store = this.storesByCode[store_code]
              if(store) {
                return store.label
              }
            }
            return ''
          }
        },
        //------------------------------------------------------------------------------

        {
          name: 'status',
          align: 'left',
          label: '状態',
          format: v => {
            const status = {disabled: '無効', enabled: '有効'}
            return status[v] ?? '不明'
          },
        },
        {
          name: 'auth_ids',
          align: 'left',
          label: '認証',
          format: formatMap(v => this.authMap[v]?.name ?? '不明'),
        },
        {
          name: 'role_ids',
          align: 'left',
          label: '権限',
          format: formatMap(v => this.roleMap[v]?.fullName ?? '不明'),
        },
      ]

      // authsが空の場合、認証カラム(colsの3つめの要素)を削除する
      if(!this.auths?.length) {
        cols.splice(2, 1)
      }

      return cols
    },

    menu() {
      const menu = [
        {
          icon:     'add',
          label:    '新規作成',
          disabled: !this.asAdmin,
          click:    this.create,
        },
        {
          icon:     'edit',
          label:    '編集',
          disabled: this.selected.length !== 1,
          click:    this.update,
        },
      ]

      if(this.asAdmin) {
        menu.push({
          icon:     'delete',
          label:    '削除',
          disabled: this.selected.length === 0 || this.selected.some(i => i.id === this.authId),
          click:    this.destroy,
        })
      }

      return menu
    },
  },

  async created() {
    await Promise.all([
      this.getSectionsTree(),
      this.getAuths(),

      // キョーエイ特別処置: 店舗テーブル --------------------------------------------
      this.getStores(),
      //------------------------------------------------------------------------------
    ])
  },

  methods: {
    ...sectionsTree.mapActions(['getSectionsTree']),
    ...auths.mapActions(['getAuths']),

    // キョーエイ特別処置: 店舗テーブル --------------------------------------------
    async getStores() {
      this.storesByCode = await storesApi.getKeyByCode()
    },
    //------------------------------------------------------------------------------

    async create() {
      await this.$router.push({name: 'UsersForm', params: {id: 'new'}})
    },

    async update() {
      await this.$router.push({name: 'UsersForm', params: {id: this.selected[0].id}})
    },

    async destroy() {
      await this.$refs.table.dialogConfirm('name')

      await this.$http.$post('/_api/users/bulk-delete', this.selected.map(i => i.id))
      await this.$refs.table.reload()
    },
  },
}
</script>
