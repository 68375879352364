
import '@/aax/style.sass'
import AaxPlugin from '@/aax'

import AqPage from './AqPage'

const layoutPlugin = {
  install(Vue, options) {
    Vue.use(AaxPlugin, options)

    Vue.component('AqPage', AqPage)
  },
}

export {
  layoutPlugin,
}
