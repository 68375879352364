
<template lang="pug">
aq-page(elevated)
  template(v-slot:header-title)
    slot

  template(v-slot:default)
    .q-ma-lg.column.no-wrap.items-center.q-gutter-lg
      aq-btn(
        label="テスト: PHPログ出力"
        @click="onTestLog"
      )
      aq-btn(
        label="テスト: PHP例外発生"
        @click="onTestException"
      )
      aq-btn(
        label="テスト: Javascript例外発生"
        @click="onTestJavascriptException"
      )
      aq-btn(
        label="テスト: ajaxエラー例外発生"
        @click="onTestAjaxExceptionWithCatch"
      )
      aq-btn(
        label="テスト: スタックトレース"
        @click="onTestStacktrace"
      )
</template>

<script>
import StackTrace from 'stacktrace-js'

import AqBtn from '@/aax/components/AqBtn.vue'

export default {
  components: {AqBtn},
  $http: true,

  methods: {
    async onTestLog() {
      await this.$http.put('/_api/test/log')
      this.$q.notify('テスト: PHPログ出力 実行済み')
    },

    async onTestException() {
      await this.$http.put('/_api/test/exception')
      this.$q.notify('テスト: PHP例外発生 実行済み')
    },

    onTestJavascriptException() {
      this.$q.notify('テスト: Javascript例外発生 実行済み')
      throw new Error("######### テスト: Javascript例外発生 #########")
    },

    async onTestAjaxExceptionWithCatch() {
      this.$q.notify('テスト: ajaxエラー例外発生')
      await this.$http.get('/_api/kanarazu404')
    },

    async onTestStacktrace() {
      const stack = await StackTrace.get()
      console.log(stack) // eslint-disable-line no-console
      this.$q.notify('テスト: スタックトレース 実行済み')
    },
  },
}
</script>
