
<!--
インストール必要:
yarn add --dev mime-match file-type
 -->

<template lang="pug">
aq-field.a-avatar-edit
  .fit(
    @click="select"
    @dragenter="dragenter"
    @dragover.prevent="dragover"
    @dragleave="dragleave"
    @drop.prevent="drop"
  )
    .a-avatar-edit__container(
      :style="containerStyle"
    )
      template(v-if="value")
        img(
          :src="value"
          :style="imageStyle"
        )

        q-btn.a-avatar-edit__clear(
          dense round unelevated
          text-color="white"
          icon="clear"
          size="sm"
          @click.stop="clear"
        )

      q-icon.absolute-center(
        v-else
        color="grey-4"
        name="image"
        :size="iconSizeStyle"
      )

    transition(name="a-avatar-edit__drop-animation")
      template(v-if="over")
        .a-avatar-edit__drop-overlay(v-if="error === null")
          .a-avatar-edit__drop-overlay-border
          .a-avatar-edit__drop-text ドロップで<br>置き換え

        .a-avatar-edit__drop-denied(v-else)
          q-icon.absolute-center(
            color="red"
            name="clear"
            :size="iconSize"
          )
          .a-avatar-edit__drop-text(v-html="error")
</template>

<script>
import FileType from 'file-type/browser'
import mimeMatch from 'mime-match'

import {blobToDataURL, blobToImage, resizeImage, cropImage, getExifOrientationFromBlob, correctOrientationImage, canvasToDataURL} from '@/aax/helpers/image'

import AqField from './AqField'

export default {
  name: 'a-avatar-edit',

  components: {AqField},

  props: {
    value:  String,
    width:  {type: [String, Number], default: 150},
    height: {type: [String, Number], default: 150},
    accept: {type: String, default: 'image/jpeg,image/png'},
    edgeRadius: String,
    jpegResolution: {type: [Number, String], default: Infinity},
    jpegTranscode:  [Number, String],
    jpegCorrectOrientation: {type: Boolean, default: false},
  },

  data() {
    return {
      inner: false,
      over: false,
      error: null,
    }
  },

  computed: {
    accept_() {
      return this.accept.split(',')
    },

    containerStyle() {
      return {
        width:  this.width  + 'px',
        height: this.height + 'px',
      }
    },

    iconSizeStyle() {
      return Math.min(this.width, this.height) * .8 + 'px'
    },

    imageStyle() {
      return {
        width:  this.width  + 'px',
        height: this.height + 'px',
        borderRadius: this.edgeRadius,
      }
    },
  },

  methods: {
    select() {
      const el = document.createElement('input')
      el.type = 'file'
      el.accept = this.accept
      el.onchange = ev => {
        this.change(ev.target.files[0])
      }
      el.click()
    },

    dragenter(ev) {
      this.inner = true
      this.updateDragItems(ev.dataTransfer.items)
    },

    dragover(ev) {
      this.inner = false
      this.over = true
      this.updateDragItems(ev.dataTransfer.items)
    },

    dragleave(ev) {
      if(!this.inner) {
        this.over = false
      }
      this.inner = false
      this.updateDragItems(ev.dataTransfer.items)
    },

    updateDragItems(items) {
      if(items.length !== 1) {
        this.error = '画像は<br>1つまで'
      }
      else if(!this.accept_.some(mimeMatch(items[0].type))) {
        this.error = '無効な<br>ファイル種別'
      }
      else {
        this.error = null
      }
    },

    async drop(ev) {
      this.inner = false
      this.over = false

      if(ev.dataTransfer.files.length === 1) {
        await this.change(ev.dataTransfer.files[0])
      }
    },

    async change(file) {
      if(!this.accept_.some(mimeMatch(file.type))) {
        return
      }

      const fileType = await FileType.fromBlob(file)
      if(fileType == null || !this.accept_.some(mimeMatch(fileType.mime))) {
        this.$notifyError('無効なファイルです')
        return
      }

      // JPEG画像
      if(fileType.mime === 'image/jpeg') {
        const image = await blobToImage(file)

        let canvas = resizeImage(image, this.jpegResolution)
        canvas = cropImage(canvas, this.width, this.height)

        // TODO: image の orientation 値を修正する
        //       getExifOrientationFromBlob, correctOrientationImage を使う？

        if(canvas !== image) {
          const dataUrl = canvasToDataURL(canvas, 'image/jpeg', this.jpegTranscode)
          this.$emit('input', dataUrl)
          return
        }
      }

      // PNG画像
      if(fileType.mime === 'image/png') {
        const image = await blobToImage(file)

        let canvas = cropImage(image, this.width, this.height)

        if(canvas !== image) {
          const dataUrl = canvasToDataURL(canvas, 'image/png')
          this.$emit('input', dataUrl)
          return
        }
      }

      this.$emit('input', await blobToDataURL(file))
    },

    clear() {
      this.$emit('input', '')
    },
  },
}
</script>

<style lang="sass">
.a-avatar-edit
  display: inline-block

  .q-field__control
    padding: 0

.a-avatar-edit__container
  margin: 12px
  position: relative

.a-avatar-edit__clear
  background-color: rgba(0, 0, 0, .5)
  position: absolute
  right: -6px
  top: -6px

.a-avatar-edit__drop-animation-enter-active,
.a-avatar-edit__drop-animation-leave-active
  transition: opacity .2s

.a-avatar-edit__drop-animation-enter,
.a-avatar-edit__drop-animation-leave-to
  opacity: 0

.a-avatar-edit__drop-overlay,
.a-avatar-edit__drop-denied
  bottom: 0
  left: 0
  pointer-events: none
  position: absolute
  right: 0
  top: 0

.a-avatar-edit__drop-overlay
  background-color: rgba(0, 0, 0, .3)

.a-avatar-edit__drop-overlay-border
  border: 4px dashed rgba(255, 255, 255, .8)
  border-radius: 10px
  bottom: 4px
  left: 4px
  position: absolute
  right: 4px
  top: 4px

.a-avatar-edit__drop-denied
  background-color: rgba(255, 0, 0, .3)

.a-avatar-edit__drop-text
  color: white
  font-weight: bold
  left: 50%
  position: absolute
  text-align: center
  top: 50%
  transform: translate(-50%, -50%)
</style>
