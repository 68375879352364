
<template lang="pug">
q-layout(view="lHr LpR lFr")
  RouterViewDrawer(
    v-model="drawer"
    :width="drawerWidth"
  )
    template(v-slot:append="slotData")
      slot(name="append" v-bind="slotData")

    template(v-slot:footer-append="slotData")
      slot(name="footer-append" v-bind="slotData")

  q-page-container
    //- TODO: keep-aliveの実装、カスタマイズ性をどうするか
    router-view(v-if="show")
    Notfound(v-else)
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

import RouterViewDrawer from './RouterViewDrawer.vue'
import Notfound from '@/aax/views/Notfound.vue'

export default {
  components: {RouterViewDrawer, Notfound},

  props: {
    drawerWidth: {type: Number},
  },

  data() {
    return {
      drawer: false,
    }
  },

  computed: {
    ...aax.mapGetters(['hasRole']),

    show() {
      return this.hasRole(this.$route.meta?.roles)
    },
  },

  created() {
    this.$event.on('open-drawer', this.onOpenDrawer)
  },

  methods: {
    onOpenDrawer() {
      this.drawer = true
    },
  },
}
</script>
