
<template lang="pug">
aq-input(
  clearable hide-bottom-space
  v-bind="$attrs"
  :label="label"
  :maxlength="maxlength"
  :style="style_"
  :value="text"
  @input="updateText"
)
</template>

<script>
import {debounce} from 'lodash-es'

import AqInput from './AqInput.vue'

export default {
  name: 'a-filter-input',

  components: {AqInput},

  props: {
    value:     {type: Object, required: true},
    label:     {type: String, default: '検索'},
    width:     {type: [Number, String], default: 200},
    debounce:  {type: [Number, String], default: 500},
    maxlength: {type: [Number, String], default: 250},
  },

  data() {
    return {
      text: this.value.filterText ?? '',
      debounceInput: debounce(this.input, this.debounce),
    }
  },

  computed: {
    style_() {
      return {
        width: this.width + 'px',
      }
    },
  },

  watch: {
    'value.filterText'(value) {
      this.text = value ?? ''
    },
  },

  methods: {
    updateText(value) {
      if(value === null) {
        this.text = ''
        this.input()
      }
      else {
        this.text = value
        this.debounceInput()
      }
    },

    input() {
      this.$emit('input', {
        ...this.value,
        filterText: this.text,
      })
    },
  },
}
</script>
