
<template lang="pug">
div
  component(
    v-if="status"
    :is="status"
  )

  slot(v-else)
</template>

<script>
import ALoading  from './ALoading.vue'
import ANotfound from './ANotfound.vue'

export default {
  name: 'a-suspense',
  components: {ALoading, ANotfound},

  props: {
    loading: {type: Function, required: true},
  },

  data() {
    return {
      status: 'ALoading',
    }
  },

  async mounted() {
    try {
      const result = await this.loading()
      this.status = null
      await this.$nextTick()
      this.$emit('loaded', result)
    }
    catch(e) {
      this.status = 'ANotfound'
      if(!e.response || e.response.status !== 404) {
        throw e
      }
    }
  },
}
</script>
