
import {escape, isString} from 'lodash-es'
import {Dialog} from 'quasar'

// ダイアログ (Quasar Framework実装)
// 引数: 順序不問
//  object:
//    すべての引数がマージされて、Quasar Dialog の opts に渡される
//  string|string[]:
//    最初の出現はタイトル、次は本文となる。それ以降の出現は無視される
//    string[] の場合は、配列の要素が行として連結される

let router

function dialog(...args) {
  return new Promise((resolve, reject) => {
    const opts = {html: true}
    let texts = []

    for(const arg of args) {
      if(isString(arg) || Array.isArray(arg)) {
        texts.push(arg)
      }
      else {
        Object.assign(opts, arg)
      }
    }

    const [title, message] = texts.slice(0, 2).map(text => {
      if(opts.html) {
        if(!Array.isArray(text))  text = text.split("\n")
        if(!opts.noEscape)        text = text.map(escape)
        text = text.join('<br>')
      }
      else if(Array.isArray(text)) {
        text = text.join("\n")
      }
      return text
    })

    if(title)   opts.title   = title
    if(message) opts.message = message

    router?.$lock.retain()
    Dialog.create(opts)
    .onOk     (data => {resolve(data)          })
    .onCancel (()   => {reject(new Error())    })
    .onDismiss(()   => {router?.$lock.release()})
  })
}

// 必ずOKを押させるダイアログボックス

function dialogAlert(...args) {
  return dialog({
    persistent: true,
  }, ...args)
}

// OK/Cancelの二択のダイアログ

function dialogConfirm(...args) {
  return dialog({
    cancel: true,
  }, ...args)
}

// プロンプト付きのダイアログ

import DialogPrompt from './dialog-prompt.vue'

function dialogPrompt(...args) {
  return dialog({
    component: DialogPrompt,
    persistent: true,
  }, ...args)
}

// スライダー付きのダイアログ

import DialogSlider from './dialog-slider.vue'

function dialogSlider(...args) {
  return dialog({
    component: DialogSlider,
  }, ...args)
}

// プラグイン

const dialogPlugin = {
  install(Vue, options) {

    router = options?.router

    Vue.prototype.$dialog        = dialog
    Vue.prototype.$dialogAlert   = dialogAlert
    Vue.prototype.$dialogConfirm = dialogConfirm
    Vue.prototype.$dialogPrompt  = dialogPrompt
    Vue.prototype.$dialogSlider  = dialogSlider
  },
}

//

export default dialog

export {
  dialogAlert,
  dialogConfirm,
  dialogPrompt,
  dialogSlider,
  dialogPlugin,
}
