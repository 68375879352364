
<template lang="pug">
aq-page(elevated)
  template(v-slot:header-title)
    slot

  template(v-slot:default)
    .q-ma-lg.column.no-wrap.items-center
      SlideSwitch(
        v-model="confirmed"
        label="スライドして削除"
        style="width: 200px; height: 36px"
        @confirm="onConfirm"
      )

      q-btn.q-mt-lg(
        push
        color="negative"
        label="リセット"
        :disable="!confirmed"
        @click="confirmed = false"
      )

      aq-btn.q-mt-lg(
        color="negative"
        label="ダイアログ"
        @click="onDialog"
      )

      aq-btn.q-mt-lg(
        color="red"
        label="プロンプト"
        @click="onPrompt"
      )
</template>

<script>
import SlideSwitch from '@/aax/components/SlideSwitch.vue'

export default {
  components: {SlideSwitch},

  data() {
    return {
      confirmed: false,
    }
  },

  methods: {
    onConfirm() {
      this.$notifySuccess('SlideSwitch オン')
    },

    async onDialog() {
      await this.$dialogSlider(
        'テストです',
        'このユーザーを削除します。この操作は取り消せません。よろしいですか？',
        {
          label: 'スライドして削除'
        }
      )
    },

    async onPrompt() {
      const result = await this.$dialogPrompt('テストです', 'タイトルです')
      this.$notify('入力=' + result)
    },
  },
}
</script>
