
<template lang="pug">
q-toolbar(
  v-bind="attrs"
  v-on="$listeners"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

export default {
  name: 'aq-toolbar',

  provide() {
    return {
      inToolbar: true,
    }
  },

  computed: {
    ...aax.mapGetters(['getThemeAttrs']),

    attrs() {
      const attrs = this.getThemeAttrs()

      return {
        ...attrs.toolbar,
        ...this.$attrs,
      }
    },
  },
}
</script>
