
// 操作禁止対象をチェックする ※権限の削除不可は無効化
function isEditableSection(section_key) {
  return section_key !== ''
}

function isEditableRole(role_key, section_key) {
  return true // ※削除不可は存在しない
  // return section_key !== '' || !['dev', 'admin', 'user'].includes(role_key)
}

// _api/sections/tree から取得したセクションツリーから id をキーとした辞書を作成する
function makeRoleMapFromSectionsTree(sectionsTree) {
  const map = {}
  for(const section of sectionsTree ?? []) {
    for(const role of section.roles) {

      let fullKey = role.key
      if(section.key !== '') {
        fullKey += '@' + section.key
      }

      let fullName = role.name
      if(section.key !== '') {
        fullName += '@' + section.name
      }

      map[role.id] = {
        ...role,
        fullKey,
        fullName,
      }
    }
  }
  return map
}

// _api/sections/tree から取得したセクションツリーをQTreeのパラメーターに変換する
function makeQTreeNodesFromSectionsTree(sectionsTree) {
  const nodes = []
  for(const section of sectionsTree ?? []) {

    const children = []
    for(const role of section.roles) {

      // role: 操作禁止対象には*をつける
      let label = role.name
      if(!isEditableRole(role.key, section.key)) {
        label += '*'
      }

      // role: 権限のフルキー
      let fullKey = role.key
      if(section.key) {
        fullKey += '@' + section.key
      }

      // role:
      children.push({
        header: 'role',
        // icon: 'label',
        key: role.id,
        label,
        fullKey,
        data: {
          ...role,
          section,
        },
      })
    }

    // section: 操作禁止対象には*をつける
    let label = section.name
    if(!isEditableSection(section.key)) {
      label += '*'
    }

    // section:
    nodes.push({
      header: 'section',
      // icon: 'computer',
      key: '_' + section.id,
      label,
      fullKey: section.key,
      data: section,
      noTick: true,
      children,
    })
  }

  return nodes
}

//
export {
  isEditableSection,
  isEditableRole,
  makeRoleMapFromSectionsTree,
  makeQTreeNodesFromSectionsTree,
}
