
import Axios from 'axios'

import store from '@/store'

import './console-override'
import {notifyError} from './notify'

// Laravelバリデーション違反応答ハンドラ

function onValidationFailDefault(e) {
  notifyError(Object.values(e))
}

// 例外ハンドラ

async function errorHandler(e, onValidationFail = onValidationFailDefault) {

  // nullの場合はスルー
  if(e == null) {
    return
  }

  // axiosのキャンセルの場合はスルー
  if(Axios.isCancel(e)) {
    return
  }

  // Quasarのdialogなどのキャンセルの場合はスルー
  if(e.name === 'Error' && e.message === '') {
    return
  }

  // axiosのエラーの場合
  if(e.name === 'Error' && e.request != null) {
    console.former.error(e) // eslint-disable-line no-console

    // 接続できない場合
    if(e.response == null) {
      notifyError('通信エラーが発生しました')
      return
    }

    // Laravelバリデーション違反応答の場合
    if(e.response.status === 422 && e.response.data != null) {
      if(e.response.data.errors) {
        onValidationFail(e.response.data.errors)
        return
      }
      if(e.response.data.message) {
        notifyError(e.response.data.message)
        return
      }
    }

    // それ以外のサーバーエラーコード
    if(!store.getters['aax/isProduction']) {
      notifyError('サーバーエラー status=' + e.response.status + ' content=' + JSON.stringify(e.response.data).substring(0, 1000))
      return
    }

    notifyError('サーバーエラー' + e.response.status + 'が発生しました')
    return
  }

  // それ以外のエラー
  console.error(e) // eslint-disable-line no-console
}

// 422エラー作成
function create422(message) {
  const error = new Error(message);
  error.request = {}
  error.response = {status: 422, data: {message}}
  return error
}

// プラグイン

const errorHandlerPlugin = {
  install(Vue, _options) {
    Vue.prototype.$handler = errorHandler
  },
}

//

export default errorHandler

export {
  errorHandlerPlugin,
  create422,
}
