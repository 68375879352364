
<template lang="pug">
q-item(
  v-close-popup="!disabled"
  v-bind="$attrs"
  clickable dense
  :class="class_"
  :disabled="disabled"
  v-on="listeners"
)
  q-item-section(
    v-if="icon"
    side
  )
    q-icon(
      :class="class_"
      :name="icon"
    )

  q-item-section
    q-item-label
      slot
</template>

<script>
export default {
  name: 'a-dropdown-item',

  props: {
    color:    String,
    disabled: Boolean,
    icon:     String,
  },

  computed: {
    class_() {
      return this.color ? ['bg-' + this.color, 'text-white'] : null
    },

    listeners() {
      return {
        ...this.$listeners,
        click: this.onClick,
      }
    },
  },

  methods: {
    onClick(...args) {
      if(!this.disabled) {
        this.$emit('click', ...args)
      }
    },
  },
}
</script>
