
<template lang="pug">
q-dialog(
  v-bind="$attrs"
  v-model="dialog"
  :persistent="persistent"
  :no-esc-dismiss="noEscDismiss"
  :no-backdrop-dismiss="noBackdropDismiss"
  @hide="onDialogHide"
)
  q-card.q-dialog-plugin
    template(v-if="html")
      q-card-section.q-dialog__title(
        v-if="title"
        v-html="title"
      )

      q-card-section.q-dialog__message.scroll(
        v-if="message"
        v-html="message"
      )

    template(v-else)
      q-card-section.q-dialog__title(
        v-if="title"
      ) {{title}}

      q-card-section.q-dialog__message.scroll(
        v-if="message"
      ) {{message}}

    q-card-section.scroll
      q-input(
        v-model="text"
        autofocus dense
        maxlength="250"
        @keydown="onKeyDown"
        @keyup="onKeyUp"
      )

    q-card-actions(align="right")
      q-btn(
        flat
        label="キャンセル"
        @click="onCancelClick"
      )

      q-btn(
        flat
        color="primary"
        label="OK"
        @click="onOKClick"
      )
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    model: {type: String, default: ''},

    html: Boolean,
    persistent: Boolean,
    noEscDismiss: Boolean,
    noBackdropDismiss: Boolean,
  },

  data() {
    return {
      dialog: false,
      text: this.model,
      keyDownCode: null,
    }
  },

  methods: {
    onKeyDown(e) {
      if((e.keyCode == 13 || e.keyCode == 229) && this.keyDownCode === null) {
        this.keyDownCode = e.keyCode
      }
    },

    onKeyUp(e) {
      if(this.keyDownCode !== null) {
        if(this.keyDownCode == 13 && e.keyCode == 13) {
          this.onOKClick()
        }
        this.keyDownCode = null
      }
    },

    show() {
      this.dialog = true
    },

    hide() {
      this.dialog = false
    },

    onDialogHide() {
      this.$emit('hide')
    },

    onOKClick() {
      this.$emit('ok', this.text)
      this.hide()
    },

    onCancelClick() {
      this.hide()
    },
  },
}
</script>
