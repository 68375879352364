
import http from '@/aax/helpers/http'

export default {
  namespaced: true,

  state() {
    return {
      auths: null,
    }
  },

  getters: {
    authMap: state => {
      const map = {}
      for(const auth of state.auths ?? []) {
        map[auth.id] = auth
      }
      return map
    },
  },

  mutations: {
    setAuths(state, value) {state.auths = value},
  },

  actions: {
    async getAuths({state, commit}, force = false) {
      if(force || state.auths === null) {
        commit('setAuths', await http.$get('/_api/auths'))
      }
    },
  },
}
