
<template lang="pug">
aq-page
  template(v-slot:header-title-after)
    .row.items-center.q-gutter-sm
      a-filter-input(v-model="tableState")

      q-btn(
        dense flat round
        icon="more_vert"
      )
        a-menu(:menu="menu")

  template(v-slot:default)
    a-eager-table.full-height(
      ref="table"
      v-model="tableState"
      click-select
      row-key="id"
      :data="auths"
      :columns="columns"
      :loading="loading"
      :selected.sync="selected"
      selection="multiple"
      @row-dblclick="update"
    )
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const auths = createNamespacedHelpers('auths')

export default {
  $http: true,

  data() {
    return {
      tableState: {},
      selected: [],
      loading: true,
    }
  },

  computed: {
    ...auths.mapState(['auths']),

    columns() {
      return [
        {
          name: 'id',
          align: 'left',
          sortable: true,
          label: 'UUID',
        },
        {
          name: 'key',
          align: 'left',
          sortable: true,
          label: 'Key',
        },
        {
          name: 'name',
          align: 'left',
          sortable: true,
          label: '名前',
        },
      ]
    },

    menu() {
      return [
        {
          icon:     'add',
          label:    '新規作成',
          click:    this.create,
        },
        {
          icon:     'edit',
          label:    '編集',
          disabled: this.selected.length !== 1,
          click:    this.update,
        },
        {
          icon:     'delete',
          label:    '削除',
          disabled: this.selected.length === 0,
          click:    this.destroy,
        },
      ]
    },
  },

  async created() {
    await this.getAuths(true)
    this.loading = false
  },

  methods: {
    ...auths.mapActions(['getAuths']),

    async create() {
      await this.$router.push({name: 'AuthsForm', params: {id: 'new'}})
    },

    async update() {
      await this.$router.push({name: 'AuthsForm', params: {id: this.selected[0].id}})
    },

    async destroy() {
      await this.$refs.table.dialogConfirm('name')

      await this.$http.$post('/_api/auths/bulk-delete', this.selected.map(i => i.id))
      this.selected = []
      await this.getAuths(true)
    },
  },
}
</script>
