/* eslint-disable no-console, no-unused-vars */

import Vue from 'vue'

import store from '@/store'

import './console-override'
import errorHandler from './error-handler'
import errorReporterNotify from './error-reporter-notify'
import errorReporterPost from './error-reporter-post'

import StackTrace from 'stacktrace-js'

// javascriptエラーレポート

function report(source, severity, args) {

  if(!store.getters['aax/isProduction']) {
    errorReporterNotify(source, severity, args)
  }

  if(store.state.aax.error_report) {
    errorReporterPost(source, severity, args)
  }
}

// error/warning出力
// VMが直接発するconsoleログはインターセプトできない

console.override.error((...args) => {

  // ag-grid-enterpriseの試用期間のコンソール出力はレポートしないように
  if(StackTrace.getSync().some(s => s.functionName === 'LicenseManager.outputMissingLicenseKey')) {
    return
  }

  //
  console.former.error(...args)
  report('console.error', 'error', args)
})

console.override.warn((...args) => {
  console.former.warn(...args)
  report('console.warn', 'warning', args)
})

// Vueのerror/warningハンドラ
// consoleにそのまま送る

Vue.config.errorHandler = function(err, vm, info) {
  // console.error(err)
  errorHandler(err)
}

Vue.config.warnHandler = function(msg, vm, trace) {
  console.warn(msg)
}

// window.onerrorのインターセプト
window.onerror = function(msg, file, line, col, err) {
  report('window.onerror', 'error', [msg, file, line, col, err])
}

// unhandledrejection/unhandledrejectionイベントのリスナ
// chrome以外では動作しない、webpack-dev-server経由の場合は動作しない

window.addEventListener('unhandledrejection', function(e) {
  report('unhandledrejection', 'error', [e.reason])
})

window.addEventListener('rejectionhandled', function(e) {
  report('rejectionhandled', 'error', [e])
})
