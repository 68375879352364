
import Axios from 'axios'

import store from '@/store'

import {dialogAlert} from './dialog'

// axios.jsで使用
// axiosカスタマイズ: レスポンスエラーハンドリング

let recorded_version = null
let alert_once = true

function httpResponseHandling(axios) {
  axios.interceptors.response.use(
    response => {
      const version = response.headers['x-application-version']
      if(version) {
        if(recorded_version === null) {
          recorded_version = version
        }
        else if(recorded_version !== version) {
          if(alert_once) {
            alert_once = false
            dialogAlert(
              'アプリケーションの更新',
              'アプリが更新されました。ブラウザのリロードが必要です',
            )
            .then(() => {
              location.reload(true)
            })
          }
        }
      }
      return response
    },

    error => {
      if(error.response) {
        if(error.response.status === 401 || error.response.status === 419) {
          console.log(error.response.status + ' 認証無効') // eslint-disable-line no-console
          store.commit('aax/setRouteGuardActive', false)
          store.commit('aax/setAuth', {})
          return Promise.reject(new Axios.Cancel())
        }
        if(error.response.status === 502 || error.response.status === 503) {
          console.log(error.response.status + ' メンテナンス中') // eslint-disable-line no-console
          store.commit('aax/setClientMaint', {
            message: error.response.data.message,
            retryAfter: error.response.headers['retry-after'],
          })
          return Promise.reject(new Axios.Cancel())
        }
      }
      return Promise.reject(error)
    }
  )
}

//

export default httpResponseHandling
