
import Vue from 'vue'

// Vue.config.devtools = true
Vue.config.productionTip = false

import './quasar'
// import './main.sass'

import Components from './components'
Vue.use(Components)

import router from './router'
import store from './store'

// キョーエイ特別処置: サブディレクトリ運用時のパス指定 -------------------------
const baseURL = process.env.VUE_APP_PATH ?? '/'

import Axios from 'axios'
Axios.defaults.baseURL = baseURL

import http from '@/aax/helpers/http'
http.defaults.baseURL = baseURL

import {layoutPlugin} from './aax/layouts'
Vue.use(layoutPlugin, {router, http: {baseURL}})
//------------------------------------------------------------------------------

import Layout from './layout.vue'

new Vue({
  router,
  store,
  render: h => h(Layout)
}).$mount('#app')
