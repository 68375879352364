
<template lang="pug">
q-btn(
  dense flat round
  icon="menu"
  @click="click"
)
</template>

<script>
export default {
  methods: {
    click() {
      this.$event.emit('open-drawer')
    },
  },
}
</script>
