
<template lang="pug">
component(:is="'debug-' + page")
  .aax-debug__title.q-toolbar__title.ellipsis 開発者

  aq-btn-dropdown(
    flat
    :label="pageTitle"
  )
    q-list
      a-dropdown-item(
        v-for="(label, key) of pages"
        :key="key"
        @click="change(key)"
      ) {{label}}

      hr

      a-dropdown-item(
        @click="telescope"
      ) Laravel Telescope

  q-space
</template>

<script>
import {capitalize} from 'lodash-es'

import ADropdownItem from '@/aax/components/ADropdownItem.vue'
import AqBtnDropdown from '@/aax/components/AqBtnDropdown.vue'

import DebugMaintenance from './maintenance.vue'
import DebugQueue       from './queue.vue'
import DebugTest        from './test.vue'
import DebugDummy       from './dummy.vue'
import DebugPlayground  from './playground.vue'

export default {
  components: {
    ADropdownItem,
    AqBtnDropdown,

    DebugMaintenance,
    DebugQueue,
    DebugTest,
    DebugDummy,
    DebugPlayground
  },

  data() {
    return {
      pages: {
        maintenance: 'メンテナンス',
        queue:       '失敗ジョブ',
        test:        'エラーテスト',
        dummy:       'ダミーテーブル',
        playground:  'PLAYGROUND',
      },
    }
  },

  computed: {
    page() {
      let page = this.$route.query.page
      if(this.pages[page] == null) {
        page = 'maintenance'
      }
      return page
    },

    pageTitle() {
      return this.pages[this.page]
    },
  },

  methods: {
    async change(page) {
      await this.$router.push({query: {page}})
    },

    telescope() {
      window.open('telescope', '_blank')
    },
  },
}
</script>

<style lang="sass">
.aax-debug__title
  flex: none
</style>
