
<template lang="pug">
aq-table(
  ref="root"
  v-bind="$attrs"
  virtual-scroll
  :columns="columns_"
  :data="data_"
  :rowKey="rowKey"

  :pagination.sync="pagination"
  :rows-per-page-options="[0]"

  :selection="selection"
  :selected="selected"

  :virtual-scroll-slice-size="sliceSize"

  v-on="listeners"
  @row-click="onRowClick"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )

  //- EXPERIMENTAL: contextmenuイベントを取得したい場合: 標準通りに動くか？
  template(
    v-slot:body="props"
    v-if="enableContextmenu"
  )
    QTr.cursor-pointer(
      :props="props"
      @click="$emit('row-click', $event, props.row)"
      @dblclick="$emit('row-dblclick', $event, props.row)"
      @contextmenu="$emit('row-contextmenu', $event, props.row)"
    )
      QTd(
        v-for="{name, value} of props.cols"
        :key="name"
        :props="props"
      ) {{value}}
</template>

<script>
import {debounce, isFunction} from 'lodash-es'

import AqTable from './AqTable.vue'

// 現在のところ、以下のフィルタ要素は、バックエンドおよびここは対応しているが、UIが用意されていない
//  value.filterColumn | query.fc | フィルタカラム指定
//  value.filterMethod | query.fm | フィルタ方法指定

export default {
  name: 'a-base-table',

  components: {AqTable},

  props: {
    columns:   Array,
    data:      {type: Array,              default: () => []},
    rowKey:    {type: [String, Function], default: 'id'},
    selected:  Array,
    selection: {tpye: String,             default: 'none'},
    value:     {type: Object,             default: () => ({})},

    //
    debounce: {type: Number, default: 500},

    // EXPERIMENTAL: contextmenuイベントを取得したい場合: 標準通りに動くか？
    enableContextmenu: Boolean,

    // 行をクリックして選択
    clickSelect: {type: Boolean, default: false},
  },

  data() {
    return {
      listeners: {
        ...this.$listeners,
        request: this.onRequest,
        'row-dblclick': this.onRowDblclick,
      },

      pagination: {
        page: 1,
        rowsPerPage: 0,

        rowsNumber: 0,
        sortBy: null,
        descending: false,
      },
      previous: null,

      sliceSize: Math.ceil(window.screen.height / 28 * 3),
      debounceRequest: debounce(this.request, this.debounce),
    }
  },

  computed: {
    columns_() {
      return this.columns.map(column => {
        column = {...column}
        if(column.field === undefined) {
          column.field = column.name
        }
        return column
      })
    },

    data_() {
      return this.data ?? []
    },

    filter() {
      const {filterText: text, filterColumn: column, filterMethod: method} = this.value
      return {text, column, method}
    },
  },

  watch: {
    async filter() {
      await this.request()
    },
  },

  created() {
    // クエリストリングを検索状態に反映する
    const {sc, so, ft, fc, fm} = this.$route.query

    this.pagination.sortBy = sc ?? null
    this.pagination.descending = so === 'd'

    const value = {filterText: ft ?? ''}
    if(ft != null) {
      if(fc != null) value.filterColumn = fc
      if(fm != null) value.filterMethod = fm
    }

    this.$emit('input', {...this.value, ...value})
  },

  methods: {
    async onRequest({pagination: {sortBy, descending}}) {
      this.pagination.sortBy     = sortBy
      this.pagination.descending = descending
      this.debounceRequest()
    },

    async request() {
      // 読み込みを実行する必要がない場合を判定する
      const {sortBy, descending} = this.pagination
      const {text, column, method} = this.filter

      if(this.previous != null && text === this.previous.text && (!text || (column === this.previous.column && method === this.previous.method))) {
        if(sortBy === this.previous.sortBy && (!sortBy || descending === this.previous.descending)) {
          return
        }
      }

      this.previous = {sortBy, descending, text, column, method}

      this.$emit('request', await this.applyQueryString())
    },

    async applyQueryString() {
      const params = {}

      if(this.pagination.sortBy) {
        params.sc = this.pagination.sortBy
        if(this.pagination.descending) params.so = 'd'
      }

      if(this.filter.text) {
        params.ft = this.filter.text
        if(this.filter.column) params.fc = this.filter.column
        if(this.filter.method) params.fm = this.filter.method
      }

      // 検索状態をクエリストリングに反映する
      const {sc, so, ft, fc, fm, ...query} = this.$route.query
      await this.$router.replace({query: {...query, ...params}})

      return params
    },

    getSliceSize() {
      return this.sliceSize
    },

    getQTable() {
      return this.$refs.root.getQTable()
    },

    // 行をクリックして選択
    onRowClick(ev, row, index) {
      if(this.clickSelect === false || this.selection === 'none') {
        return
      }

      if(this.selection === 'multiple') {
        if(ev.metaKey || ev.ctrlKey) {
          const selected = [...this.selected]
          const i = this.findSelectedIndex(row)
          if(i === -1) {
            selected.push(row)
          }
          else {
            selected.splice(i, 1)
          }
          this.$emit('update:selected', selected)
          return
        }

        if(ev.shiftKey && 0 < this.selected.length) {
          const selected = [this.selected[this.selected.length - 1]]
          let i = this.findDataIndex(selected[0])
          if(index < i) {
            while(index <= --i) {
              selected.push(this.data[i])
            }
          }
          else {
            while(++i <= index) {
              selected.push(this.data[i])
            }
          }
          this.$emit('update:selected', selected)
          return
        }
      }

      this.$emit('update:selected', [row])
    },

    findSelectedIndex(value) {
      const getRowKey = typeof this.rowKey === 'function' ? this.rowKey : row => row[this.rowKey]
      return this.selected.findIndex(row => getRowKey(row) === getRowKey(value))
    },

    findDataIndex(value) {
      const getRowKey = typeof this.rowKey === 'function' ? this.rowKey : row => row[this.rowKey]
      return this.data.findIndex(row => getRowKey(row) === getRowKey(value))
    },

    // 行をクリックして選択時はチェックボックスのダブルクリックイベントを抑制する
    onRowDblclick(ev, row, index) {
      if(this.clickSelect && ev.target.closest('td.q-table--col-auto-width')?.querySelector('div[role=checkbox]') != null) {
        return
      }
      this.$emit('row-dblclick', ev, row, index)
    },

    // 削除の確認ダイアログを表示する
    async dialogConfirm(name) {
      // TODO: nameの扱いを単なるプロパティ名ではなくcolumnsテーブルのfield/formatを反映すべき
      const getName = isFunction(name) ? name : row => row[name]
      await this.$dialogConfirm(
        '削除の確認',
        this.selected.length === 1
          ? '「' + getName(this.selected[0]) + '」を削除します。よろしいですか？'
          : '選択された' + this.selected.length + '行を削除します。よろしいですか？'
      )
    },
  },
}
</script>
