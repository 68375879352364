
import Vue from 'vue'

const bus = new Vue

function on(events, callback) {
  for(const event of Array.isArray(events) ? events : [events]) {
    if(this.callbacks[event] == null) {
      this.callbacks[event] = [callback]
    }
    else {
      this.callbacks[event].push(callback)
    }
  }
  bus.$on(events, callback)
}

function off(events = null, callback = null) {
  if(events === null) {
    events = Object.keys(this.callbacks)
  }
  else if(!Array.isArray(events)) {
    events = [events]
  }
  for(const event_ of events) {
    const callbacks = this.callbacks[event_]
    if(callbacks != null) {
      const callbacks_ = []
      for(const callback_ of callbacks) {
        if(callback === null || callback === callback_) {
          bus.$off(event_, callback_)
        }
        else {
          callbacks_.push(callback_)
        }
      }
      this.callbacks[event_] = callbacks_
    }
  }
}

function emit(eventName, ...args) {
  bus.$emit(eventName, ...args)
}

// プラグイン
const eventPlugin = {
  install(Vue, _options) {
    Vue.mixin({
      beforeCreate() {
        this.$event = {
          callbacks: {},

          on,
          off,
          emit,
        }
      },

      beforeDestroy() {
        this.$event.off()
      },
    })
  },
}

//
export {
  eventPlugin,
}
