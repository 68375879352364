
<template lang="pug">
.absolute-center 指定されたURLは存在しません。
</template>

<script>
export default {
  name: 'a-notfound',
}
</script>
