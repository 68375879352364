
import './helpers/error-reporter'
import {errorHandlerPlugin} from './helpers/error-handler'
import {eventPlugin       } from './helpers/event'
import {httpPlugin        } from './helpers/http'
import {dialogPlugin      } from './helpers/dialog'
import {notifyPlugin      } from './helpers/notify'
import {sleepPlugin       } from './helpers/promise-sleep'

export default {
  install(Vue, options) {

    Vue.use(errorHandlerPlugin, options)
    Vue.use(eventPlugin,        options)
    Vue.use(httpPlugin,         options?.http)
    Vue.use(dialogPlugin,       options)
    Vue.use(notifyPlugin,       options)
    Vue.use(sleepPlugin,        options)
  },
}
