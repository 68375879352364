
<template lang="pug">
a-base-table(
  ref="root"
  v-bind="$attrs"
  :data="data"
  :loading="loadingCount !== 0"
  v-on="listeners"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )

  template(v-slot:pagination)
    span {{statusText}}
</template>

<script>
import errorHandler from '@/aax/helpers/error-handler'

import ABaseTable from './ABaseTable.vue'

export default {
  name: 'a-lazy-table',
  components: {ABaseTable},
  $http: true,

  props: {
    apiUrl:      {type: String,  required: true},
    queryString: {type: Object,  default: () => ({})},
    enableCount: {type: Boolean, default: false},
  },

  data() {
    return {
      data: [],
      count: null,
      finish: false,
      loadingCount: 0,
    }
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        'virtual-scroll': this.onVirtualScroll,
        request:          this.onRequest,
      }
    },

    statusText() {
      if(this.data) {
        if(this.finish || this.data.length === this.count) {
          return '全' + this.data.length + '行'
        }
        if(this.data.length < this.count) {
          return this.data.length + '行読込 / 全' + this.count + '行'
        }
        return this.data.length + '行以上'
      }
      return ''
    },
  },

  methods: {
    async onVirtualScroll({to, ref}) {
      if(!this.finish && this.loadingCount === 0 && to === this.data.length - 1) {
        try {
          ++this.loadingCount

          const params = await this.$refs.root.applyQueryString()
          this.data = this.data.concat(await this.get(this.data.length, params))
          await this.$nextTick
          ref.refresh()
        }
        finally {
          --this.loadingCount
        }
      }
    },

    async onRequest(params) {
      try {
        ++this.loadingCount
        if(1 < this.loadingCount) {
          this.$http.$cancel()
        }

        await this.getFirst(params)
        await this.$nextTick
        this.$refs.root.getQTable().scrollTo(0)
      }
      finally {
        --this.loadingCount
      }
    },

    async getFirst(params) {
      this.count = null
      this.data = await this.get(0, params)

      if(this.enableCount && !this.finish) {
        this.count = await this.$http.$get(this.apiUrl + '/count', {params: {...params, ...this.queryString}})
      }
    },

    async get(offset, params) {
      if(offset) {
        params.o = offset
      }
      params.l = Math.ceil((this.$refs.root.getSliceSize() + 1) / 100) * 100

      let data
      try {
        data = await this.$http.$get(this.apiUrl, {params: {...params, ...this.queryString}})
      }
      catch(e) {
        this.finish = true
        throw e
      }

      this.finish = data.length < params.l

      return data
    },

    async reload() {
      const {sc, so, ft, fc, fm} = this.$route.query
      await this.getFirst({sc, so, ft, fc, fm})
      this.$refs.root.getQTable().clearSelection()
      await this.$nextTick
      this.$refs.root.getQTable().scrollTo(0)
    },

    // 削除の確認ダイアログを表示する
    async dialogConfirm() {
      await this.$refs.root.dialogConfirm(...arguments)
    },
  },
}
</script>
