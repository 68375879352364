
<!-- 実験的コンポーネント -->

<template lang="pug">
q-item(
  v-close-popup="!disabled && !hasChild"
  v-bind="$attrs"
  clickable dense
  :class="class_"
  :disabled="disabled"
  v-on="listeners"
)
  q-item-section(
    v-if="icon"
    side
  )
    q-icon(
      :class="class_"
      :name="icon"
    )

  q-item-section
    q-item-label {{label}}

  template(v-if="hasChild")
    q-item-section(side)
      q-icon(name="keyboard_arrow_right")

    q-menu(
      v-if="!disabled"
      anchor="top right"
      self="top left"
    )
      q-list
        slot
</template>

<script>
export default {
  name: 'a-menu-item',

  props: {
    color:    String,
    disabled: Boolean,
    icon:     String,
    label:    String,
  },

  computed: {
    hasChild() {
      return !!this.$slots.default
    },

    class_() {
      return this.color ? ['bg-' + this.color, 'text-white'] : null
    },

    listeners() {
      return {
        ...this.$listeners,
        click: this.onClick,
      }
    },

  },

  methods: {
    onClick(...args) {
      if(!this.disabled) {
        this.$emit('click', ...args)
      }
    },
  },
}
</script>
