
<template lang="pug">
aq-page(
  elevated
  :title-after="displayName"
)
  template(
    v-slot:header-title-before
    v-if="!myProfile"
  )
    q-btn(
      dense round unelevated
      icon="arrow_back"
      @click="back"
    )

  template(
    v-slot:header-title-after
    v-if="!overwrap"
  )
    .row.items-center.q-gutter-sm
      aq-btn(
        v-if="isNew"
        color="primary"
        icon="add"
        label="作成"
        @click="create"
      )

      aq-btn(
        v-else-if="!myProfile || isChanged"
        color="primary"
        icon="check"
        label="更新"
        @click="update"
      )

  template(v-slot:default)
    component(
      v-if="overwrap"
      :is="overwrap"
    )

    aq-scroll-area.full-height(v-else)
      .q-ma-md
        q-card.q-mb-md.q-pl-md.q-pt-md
          .row
            .col-12.q-pr-md.q-pb-md
              x-avatar-edit(
                ref="icon"
                v-model="model.icon"
                edge-radius="50%"
                jpeg-resolution="512"
                :error="error.icon"
              )

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="email"
                v-model.trim="model.email"
                label="メールアドレス"
                maxlength="250"
                :autofocus="!lockEmail"
                :readonly="lockEmail"
                :error="error.email"
              )

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="name"
                v-model.trim="model.name"
                label="名前"
                maxlength="250"
                :autofocus="lockEmail"
                :error="error.name"
              )

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="password"
                v-model.trim="model.password"
                label="パスワード"
                maxlength="250"
                :error="error.password"
                :type="showPassword ? 'text' : 'password'"
              )
                template(v-slot:append)
                  q-icon.cursor-pointer(
                    :name="showPassword ? 'visibility_off' : 'visibility'"
                    @click="showPassword = !showPassword"
                  )
                template(
                  v-if="!isNew"
                  v-slot:hint
                ) 変更時のみ入力してください

            .col-12.q-pr-md.q-pb-md
              aq-input(
                ref="password2"
                v-model.trim="model.password2"
                label="パスワード (確認)"
                maxlength="250"
                :error="error.password2"
                :type="showPassword ? 'text' : 'password'"
              )
                template(v-slot:append)
                  q-icon.cursor-pointer(
                    :name="showPassword ? 'visibility_off' : 'visibility'"
                    @click="showPassword = !showPassword"
                  )
                template(
                  v-if="!isNew"
                  v-slot:hint
                ) 変更時のみ入力してください

            //- キョーエイ特別処置: ユーザー属性 -------------------------------------------
            .col-6.q-pr-md.q-pb-md
              aq-input(
                ref="empl_code"
                v-model.trim="model.empl_code"
                label="社員コード"
                maxlength="250"
                :error="error.empl_code"
              )

            .col-6.q-pr-md.q-pb-md
              aq-select(
                ref="store_code"
                v-model.trim="model.store_code"
                emit-value map-options
                label="店舗"
                :options="storeOpts"
                :error="error.store_code"
              )
            //------------------------------------------------------------------------------

            .col-12.q-pr-md.q-pb-md
              aq-select(
                ref="status"
                v-model="model.status"
                emit-value map-options
                label="状態"
                :options="statusOpts"
                :error="error.status"
              )

            .col-12.q-pr-md.q-pb-md(v-if="showAuth")
              aq-select(
                ref="auth_ids"
                v-model="model.auth_ids"
                emit-value map-options
                multiple use-chips
                label="認証"
                :options="authOpts"
                :readonly="!asAdmin"
                :error="error.auth_ids"
              )

            .col-12.q-pr-md.q-pb-md
              aq-field(:error="error.role_ids")
                x-roles-tree.q-py-sm(
                  v-model="model.role_ids"
                  :lockRoles="lockRoles"
                )
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')
const auths = createNamespacedHelpers('auths')

import FormMixin from '@/aax/helpers/form'

// キョーエイ特別処置: 店舗テーブル --------------------------------------------
import storesApi from '@/api/storesApi'
//------------------------------------------------------------------------------

export default {
  mixins: [FormMixin],

  props: {
    myProfile: Boolean,
  },

  data() {
    return {
      showPassword: false,

      // キョーエイ特別処置: 店舗テーブル --------------------------------------------
      stores: null,
      //------------------------------------------------------------------------------
    }
  },

  computed: {
    ...aax.mapGetters(['authId', 'asDev', 'asAdmin']),
    ...auths.mapState(['auths']),

    displayName() {
      return !this.myProfile && this.isSelf && this.original.name ? this.original.name + '【自分】' : this.original.name
    },

    apiUrl() {
      return '/_api/users'
    },

    parentName() {
      return 'UsersTable'
    },

    defaultModel() {
      return {
        email:     '',
        name:      '',
        password:  '',
        password2: '',
        icon:      '',
        status:    'enabled',
        role_ids:  [],
        auth_ids:  [],

        // キョーエイ特別処置: ユーザー属性 --------------------------------------------
        empl_code:  '',  // 社員コード
        store_code: '',  // 店舗コード
        //------------------------------------------------------------------------------
      }
    },

    isSelf() {
      return this.key == this.authId
    },

    showAuth() {
      return this.auths?.length
    },

    lockEmail() {
      return this.isSelf && !this.asAdmin
    },

    statusOpts(){
      const opts = [
        {value: 'disabled', label: '無効'},
        {value: 'enabled',  label: '有効'},
      ]
      if(this.isSelf) {
        for(const [index, {value}] of opts.entries()) {
          if(['disabled'].includes(value)) {
            opts[index].disable = true
          }
        }
      }
      return opts
    },

    authOpts() {
      return this.auths == null ? [] : this.auths.map(auth => ({
        value: auth.id,
        label: auth.name,
      }))
    },

    lockRoles() {
      if(this.asDev)   return this.isSelf ? ['dev']          : false
      if(this.asAdmin) return this.isSelf ? ['dev', 'admin'] : false
      return true
    },

    // キョーエイ特別処置: 店舗テーブル --------------------------------------------
    storeOpts() {
      return [
        {value: '', label: 'なし'},
        ...(this.stores?.map(v => ({value: v.id, label: v.label})) ?? [])
      ]
    },
    //------------------------------------------------------------------------------
  },

  async created() {
    await Promise.all([
      this.getAuths(),

      // キョーエイ特別処置: 店舗テーブル --------------------------------------------
      this.getStores(),
      //------------------------------------------------------------------------------
    ])
  },

  methods: {
    ...auths.mapActions(['getAuths']),

    // キョーエイ特別処置: 店舗テーブル --------------------------------------------
    async getStores() {
      this.stores = await storesApi.get()
    },
    //------------------------------------------------------------------------------

    async toParent() {
      if(this.myProfile) {
        await this.$notifySuccess('更新しました')
      }
      else {
        await this.$router.push(this.parentLocation)
      }
    },
  },
}
</script>
