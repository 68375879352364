
<template lang="pug">
aq-page
  a-notfound
</template>

<script>
import ANotfound from '@/aax/components/ANotfound.vue'

export default {
  components: {ANotfound},
}
</script>
