/* eslint-disable no-console */

// console出力のオーバーライド

const overrided = {}

console.former = {...console}

console.override = {}
for(const name in console.former) {
  console.override[name] = fn => {
    if(overrided[name] === undefined) {
      overrided[name] = [fn]
      console[name] = (...args) => {
        overrided[name].forEach(fn => {fn(...args)})
      }
    }
    else {
      overrided[name].push(fn)
    }
  }
}

console.unoverride = {}
for(const name in console.former) {
  console.unoverride[name] = fn => {
    if(overrided[name] !== undefined) {
      overrided[name] = overrided[name].filter(fn_ => fn_ !== fn)
      if(overrided[name].length === 0) {
        console[name] = console.former[name]
        delete overrided[name]
      }
    }
  }
}
