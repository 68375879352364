
<template lang="pug">
.DropFile__container(
  @dragenter="onDragEnter()"
  @dragover.prevent="onDragOver()"
  @dragleave="onDragLeave()"
  @drop.prevent="onDrop()"
)
  slot
  template(v-if="!disabled")
    .DropFile__overlay(v-if="over")
    label.DropFile__input-label
      input.DropFile__input(
        type="file"
        :multiple="multiple"
        @change="onInputChange"
      )
</template>

<script>
export default {
  props: {
    disabled: {default: false},
    multiple: {default: false},
  },

  data() {
    return {
      inner: false,
      over: false,
    }
  },

  methods: {
    // ファイルなどをドロップする要素が子要素を持っていても正しくホバーエフェクトを表示する方法
    // 参考: https://qiita.com/sounisi5011/items/dc4878d3e8b38101cf0b

    onDragEnter() {
      this.inner = true
    },

    onDragOver() {
      this.inner = false
      this.over = true
    },

    onDragLeave() {
      if(!this.inner) {
        this.over = false
      }
      this.inner = false
    },

    onDrop() {
      this.inner = false
      this.over = false

      if(!this.multiple && 1 < event.dataTransfer.files.length) {
        this.$notifyError('複数ファイルのドロップはできません')
      }
      else {
        this.$emit('drop', event.dataTransfer)
      }
    },

    onInputChange() {
      if(event.target.files.length) {
        this.$emit('change', event.target.files)
      }
      event.target.value = ''
    },
  },
}
</script>

<style lang="sass">
.DropFile__container
  overflow: hidden
  position: relative

.DropFile__input-label
  cursor: pointer
  height: 100%
  left: 0
  position: absolute
  top: 0
  width: 100%

.DropFile__input
  height: 0
  left: 0
  opacity: 0
  outline: none
  overflow: hidden
  position: absolute
  top: 0
  width: 0

.DropFile__overlay
  background-color: black
  bottom: 0
  left: 0
  opacity: .5
  position: absolute
  right: 0
  top: 0
</style>
