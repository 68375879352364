
import Vue from 'vue'

import VueRouter from 'vue-router'

// suppressRedundantErrors(router)
// push / replace で起こる冗長なエラーを抑制する

function handleError(e) {
  // vue-routerのエラーをチェックし、不要なエラーは揉み消す
  // project://vue/node_modules/vue-router/src/util/warn.js
  if(!e._isRouter) {
    throw e
  }
  if(e.type !== VueRouter.NavigationFailureType.aborted && e.type !== VueRouter.NavigationFailureType.duplicated) {
    console.log(e.message) // eslint-disable-line no-console
  }
}

function suppressRedundantErrors(router) {
  const push    = (...args) => Object.getPrototypeOf(router).push   .call(router, ...args)
  const replace = (...args) => Object.getPrototypeOf(router).replace.call(router, ...args)

  router.$former = {
    push,
    replace,
  }

  router.push = async (to, ...args) => {
    try {
      return await push(to, ...args)
    }
    catch(e) {
      handleError(e)
    }
  }

  router.replace = async (to, ...args) => {
    try {
      return await replace(to, ...args)
    }
    catch(e) {
      handleError(e)
    }
  }
}

// rememberQueryString(router)
// route name ごとに query string を記録して、
// push / replace で query が定義されていない場合、自動的に復帰ささせる
// suppressRedundantErrorsの機能を含む

function rememberQueryString(router) {
  const queryStrings = {}

  router.afterEach(to => {
    if(to.name != null && to.query != null) {
      queryStrings[to.name] = {...to.query}
    }
  })

  const get = to => {
    if(to.name != null && to.query == null && queryStrings[to.name] != null) {
      to.query = queryStrings[to.name]
    }
    return to
  }

  const push    = (...args) => Object.getPrototypeOf(router).push   .call(router, ...args)
  const replace = (...args) => Object.getPrototypeOf(router).replace.call(router, ...args)

  router.$former = {
    push,
    replace,
  }

  router.push = async (to, ...args) => {
    try {
      return await push(get(to), ...args)
    }
    catch(e) {
      handleError(e)
    }
  }

  router.replace = async (to, ...args) => {
    try {
      return await replace(get(to), ...args)
    }
    catch(e) {
      handleError(e)
    }
  }
}

// navigationInhibiter(router)
// ナビゲーションを抑止する機構を追加する

function navigationInhibiter(router) {
  let count = 0

  router.beforeEach((_to, _from, next) => {
    if(count === 0) {
      next()
    }
    else {
      next(false)
    }
  })

  router.$lock = {
    get count() {
      return count
    },

    retain() {
      ++count
    },

    release() {
      --count
    },

    async scope(callback) {
      try {
        ++count
        return await callback()
      }
      finally {
        --count
      }
    },
  }
}

// navigationDirectionDetector(router)
// navigationDirectionIsBack()
// navigationDirectionIsForward()
// ナビゲーション方向検出
// Is〜はbeforeEachガードで呼び出されることが前提

let lastAaxkey = 0

function navigationDirectionDetector(router) {
  router.afterEach(() => {
    Vue.nextTick(() => {
      if(window.history.state?.aaxkey == null) {
        window.history.replaceState({
          ...window.history.state ?? {},
          aaxkey: Date.now(),
        }, '')
      }
      lastAaxkey = window.history.state?.aaxkey ?? 0
    })
  })
}

function navigationDirectionIsBack() {
  const aaxkey = window.history.state?.aaxkey ?? 0
  return aaxkey < lastAaxkey
}

function navigationDirectionIsForward() {
  const aaxkey = window.history.state?.aaxkey ?? 0
  return lastAaxkey < aaxkey
}

//

export {
  suppressRedundantErrors,
  rememberQueryString,
  navigationInhibiter,
  navigationDirectionDetector,
  navigationDirectionIsBack,
  navigationDirectionIsForward,
}
