
<template lang="pug">
q-scroll-area(
  ref="root"
  v-bind="attrs"
  v-on="$listeners"
)
  template(
    v-for="(_, slotName) of $scopedSlots"
    v-slot:[slotName]="slotData"
  )
    slot(
      v-bind="slotData"
      :name="slotName"
    )
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

export default {
  name: 'aq-scroll-area',

  props: {
    dark: {type: Boolean, default: null},
  },

  computed: {
    ...aax.mapGetters(['getThemeAttrs']),

    attrs() {
      const attrs = this.getThemeAttrs(this.dark)

      return {
        dark: this.dark,
        ...attrs.scrollArea,
        ...this.$attrs,
      }
    },
  },

  methods: {
    ...[
      'getScrollTarget',
      'getScrollPosition',
      'setScrollPosition',
    ].reduce((s, n) => ({...s, [n]() {return this.$refs.root[n](...arguments)}}), {}),
  },
}
</script>
