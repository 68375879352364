
<template lang="pug">
Maintenance
  RouterView(v-if="isAuthorized")
    template(v-slot:footer-append)
      q-item(
        clickable v-ripple
        @click="logout"
      )
        q-item-section(avatar)
          q-icon(name="exit_to_app")
        q-item-section ログアウト
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const aax = createNamespacedHelpers('aax')

import Maintenance from '@/aax/layouts/Maintenance.vue'
import RouterView  from '@/aax/layouts/RouterView.vue'

export default {
  components: {Maintenance, RouterView},

  computed: {
    ...aax.mapGetters(['isUnauthorized', 'isAuthorized']),
  },

  watch: {
    async isUnauthorized(value) {
      if(value) {
        // キョーエイ特別処置: サブディレクトリ運用時のパス指定 -------------------------
        window.location.href = (process.env.VUE_APP_PATH ?? '/') + '_sso/login'
        //------------------------------------------------------------------------------
      }
    },
  },

  methods: {
    async logout() {
      await this.$dialogConfirm(
        'ログアウト',
        'ログアウトします。よろしいですか？',
      )

      // unauthorize アクションの方が遷移が綺麗だが、ナビゲーションガードが働かないので、 直接 location.href する
      // キョーエイ特別処置: サブディレクトリ運用時のパス指定 -------------------------
      window.location.href = (process.env.VUE_APP_PATH ?? '/') + '_sso/logout'
      //------------------------------------------------------------------------------
    },
  },
}
</script>
