
<template lang="pug">
.slide-switch(ref="container")
  .slide-switch__label-right(
    :style="{left: knobWidth * .75 + 'px'}"
  ) {{label}}
  .slide-switch__label-left-mask(
    :style="{width: left + knobWidth * .5 + 'px'}"
  )
    .slide-switch__label-left(
      :style="{width: labelWidth - knobWidth + 'px'}"
    ) {{labelLeft}}

  .slide-switch__frame
    .slide-switch__knob-mask
      .slide-switch__knob-container
        button.slide-switch__knob(
          ref="knob"
          :class="'bg-' + knobColor"
          :style="{left: left + 'px', width: knobWidth + 'px'}"
          @mousedown="onMouseDown"
        )
          .slide-switch__knob-shadow
          .slide-switch__knob-top
            .slide-switch__knob-icon
              q-icon(
                :name="knobIcon"
                :size="knobWidth * .75 + 'px'"
              )
</template>

<script>
export default {
  props: {
    value:     Boolean,
    labelLeft: String,
    label:     String,
    knobWidth: {type: Number, default: () => 36},
    knobColor: {type: String, default: () => 'negative'},
    knobIcon:  {type: String, default: () => 'forward'},
    dark:      {type: Boolean, default: null},
  },

  data() {
    return {
      left: 0,
      drag: null,
      labelWidth: 0,
      springId: null,
    }
  },

  computed: {
    isDark() {
      return this.dark === null ? this.$q.dark.isActive : this.dark
    },
  },

  watch: {
    value(value) {
      if(!value && this.left) {
        this.spring()
      }
    },
  },

  mounted() {
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)
    this.updateLabelWidth()
  },

  beforeDestroy() {
    this.stopSpring()
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)
  },

  methods: {
    onMouseDown(e) {
      if(!this.value) {
        this.stopSpring()
        this.drag = this.left - e.screenX
        this.updateLabelWidth()
      }
    },

    onMouseMove(e) {
      if(this.drag !== null) {
        this.updateLeft(e)
      }
    },

    onMouseUp(e) {
      if(this.drag !== null) {
        if(this.updateLeft(e)) {
          this.drag = null
          this.spring()
        }
      }
    },

    updateLeft(e) {
      this.updateLabelWidth()
      this.left = Math.max(e.screenX + this.drag, 0)
      const max = this.$refs.container.clientWidth - (this.$refs.knob.clientWidth + 2)
      if(this.left < max) {
        return true
      }
      this.left = max
      this.drag = null
      this.$emit('input', true)
      this.$emit('confirm')
      return false
    },

    updateLabelWidth() {
      this.labelWidth = this.$refs.container.clientWidth
    },

    spring() {
      this.stopSpring()
      let time = performance.now()
      const fn = () => {
        const next = performance.now()
        this.left *= Math.pow(.98, next - time)
        time = next
        if(this.left < 2) {
          this.left = 0
          return
        }
        this.springId = window.requestAnimationFrame(fn)
      }
      this.springId = window.requestAnimationFrame(fn)
    },

    stopSpring() {
      window.cancelAnimationFrame(this.springId)
      this.springId = null
    },
  },
}
</script>

<style lang="sass">
.slide-switch
  background-color: #e0e0e0
  border-radius: 9px
  box-sizing: border-box
  position: relative
  user-select: none

.slide-switch > *
  box-sizing: border-box

.slide-switch__label-right
  align-items: center
  bottom: 0
  color: #606060
  display: flex
  justify-content: center
  position: absolute
  right: 0
  top: 4px

.slide-switch__label-left-mask
  background-color: inherit
  border-radius: 9px
  bottom: 0
  left: 0
  overflow: hidden
  position: absolute
  top: 0

.slide-switch__label-left
  align-items: center
  bottom: 0
  color: #606060
  display: flex
  justify-content: center
  left: 0
  position: absolute
  right: 0
  top: 4px

.slide-switch__frame
  border: 1px solid #c0c0c0
  border-radius: 9px
  bottom: 0
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, .66) inset
  left: 0
  position: absolute
  right: 0
  top: 0

.slide-switch__knob-mask
  border-radius: 8px
  bottom: 0
  left: 0
  overflow: hidden
  position: absolute
  right: 0
  top: -8px

.slide-switch__knob-container
  bottom: 0
  left: 0
  position: absolute
  right: 0
  top: 8px

.slide-switch__knob
  border: none
  border-radius: 8px
  height: calc(100% - 4px)
  outline: none
  position: relative
  top: 4px

.slide-switch__knob-shadow
  background-color: rgba(0, 0, 0, .33)
  border-radius: 8px
  bottom: 0
  left: 0
  position: absolute
  right: 0
  top: 0

.slide-switch__knob:active > .slide-switch__knob-top
  transform: translateY(3px)

.slide-switch__knob-top
  background-color: inherit
  border: 1px solid rgba(0, 0, 0, .1)
  border-radius: inherit
  bottom: 3px
  content: ''
  left: 0
  position: absolute
  right: 0
  top: -7px
  transition: all ease 100ms

.slide-switch__knob-icon
  align-items: center
  bottom: 0
  color: white
  display: flex
  justify-content: center
  left: 0
  position: absolute
  right: 0
  top: 0
</style>
