
import Axios from 'axios'

// import httpLoading from './http-loading'
import httpResponseHandling from './http-response-handling'

function create(options = {}) {

  // CSRFトークン検証対応
  const axios = Axios.create({
    headers: Object.assign(...['post', 'put', 'delete', 'patch'].map(method => ({
      [method]: {'X-Requested-With': 'XMLHttpRequest'},
    }))),
    ...options,
  })

  // キャンセル
  axios.$cancelTokenSource = Axios.CancelToken.source()

  axios.$cancel = () => {
    axios.$cancelTokenSource.cancel()
    axios.$cancelTokenSource = Axios.CancelToken.source()
  }

  axios.interceptors.request.use(
    config => {
      if(config.cancelToken == null) {
        config.cancelToken = axios.$cancelTokenSource.token
      }
      return config
    },

    // error => Promise.reject(error) // 不要？
  )

  // dataを直接返すリクエストメソッド
  for(const method of ['request', 'get', 'delete', 'head', 'options', 'post', 'put', 'patch']) {
    axios['$' + method] = async function() {
      return (await this[method](...arguments)).data
    }
  }

  // ローディング中インジケーター
  // httpLoading(axios)

  // レスポンスエラーハンドリング
  httpResponseHandling(axios)

  //
  return axios
}

const http = create()

// プラグイン
const httpPlugin = {
  install(Vue, options) {
    Vue.mixin({
      beforeCreate() {
        if(this.$options.$http) {
          this.$http = create(options)
        }
      },

      beforeDestroy() {
        if(this.$options.$http) {
          this.$http.$cancel()
        }
      },
    })
  },
}

//
export default http

export {
  httpPlugin,
}
