
<!-- 実験的コンポーネント -->

<template lang="pug">
q-item(
  clickable dense
  :class="clsColor"
  :disabled="disabled"
  v-close-popup="!disabled && !hasChild"
  @click="onClick"
)
  q-item-section(
    v-if="icon"
    side
  )
    q-icon(
      :class="clsColor"
      :name="icon"
    )

  q-item-section
    q-item-label {{label}}

  template(v-if="hasChild")
    q-item-section(side)
      q-icon(name="keyboard_arrow_right")

    q-menu(
      v-if="!disabled"
      anchor="top right"
      self="top left"
    )
      a-menu-0(:menu="children")
</template>

<script>
export default {
  name: 'a-menu-1',

  props: {
    children: {type: Array,    default: () => []},
    color:    String,
    disabled: Boolean,
    icon:     String,
    label:    String,
    click:    {type: Function, default: () => {}},
  },

  computed: {
    clsColor() {
      return this.color ? ['bg-' + this.color, 'text-white'] : null
    },

    hasChild() {
      return this.children.length !== 0
    },
  },

  methods: {
    async onClick() {
      if(!this.disabled) {
        await this.click()
      }
    },
  },
}
</script>
