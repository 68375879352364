
<template lang="pug">
aq-input(
  v-bind="$attrs"
  :error="error"
  :mask="mask_"
  :value="value"
  v-on="$listeners"
)
  template(v-slot:after)
    q-icon.cursor-pointer(name="event")
      q-popup-proxy(ref="popup")
        q-date(
          :mask="mask"
          :value="value"
          @input="onInput"
        )
</template>

<script>
import {isString} from 'lodash-es'
import {format, isValid} from 'date-fns'

import AqInput from './AqInput.vue'

export default {
  name: 'aq-input-date',
  components: {AqInput},

  props: {
    error: String,
    mask:  {type: String, default: 'YYYY-MM-DD'},
    value: {required: false},
  },

  computed: {
    mask_() {
      let mask_ = this.mask
      if(isString(mask_)) {
        mask_ = mask_.replaceAll(/[YMDHms]/gi, '#')
      }
      return mask_
    },
  },

  methods: {
    onInput(value) {
      this.$emit('input', value)
      this.$refs.popup.hide()
    },
  },
}
</script>
