
import Vue from 'vue'

import VueRouter from 'vue-router'
import {rememberQueryString, navigationInhibiter, navigationDirectionDetector} from '@/aax/helpers/router'

import Home           from '@/views/Home.vue'
import MyProfile      from '@/views/MyProfile.vue'
import UsersTable     from '@/views/UsersTable.vue'
import UsersForm      from '@/views/UsersForm.vue'
import AppsTable      from '@/views/AppsTable.vue'
import AppsForm       from '@/views/AppsForm.vue'
import RolesTree      from '@/views/RolesTree.vue'
import RolesForm      from '@/views/RolesForm.vue'
import SectionsForm   from '@/views/SectionsForm.vue'
import AuthsTable     from '@/views/AuthsTable.vue'
import AuthsForm      from '@/views/AuthsForm.vue'
import IdpTokensTable from '@/views/IdpTokensTable.vue'
import IdpTokensForm  from '@/views/IdpTokensForm.vue'

import Debug    from '@/aax/views/Debug'
import Notfound from '@/aax/views/Notfound'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'ホーム',
      icon: 'home',
    },
  },

  {
    path: '/myprofile',
    name: 'MyProfile',
    component: MyProfile,
    meta: {
      title: 'マイプロファイル',
      icon: 'face',
    },
  },

  {
    path: '/users',
    name: 'UsersTable',
    component: UsersTable,
    meta: {
      title: 'ユーザー',
      icon: 'people',
      roles: ['dev', 'admin'],
    },
  },
  {
    path: '/users/:id([0-9a-f-]+|new)',
    name: 'UsersForm',
    component: UsersForm,
    props: true,
    meta: {
      parent: 'UsersTable',
      roles: ['dev', 'admin'],
    },
  },

  {
    path: '/apps',
    name: 'AppsTable',
    component: AppsTable,
    meta: {
      title: 'アプリ',
      icon: 'apps',
      roles: ['dev'],
    },
  },
  {
    path: '/apps/:id([0-9a-f-]+|new)',
    name: 'AppsForm',
    component: AppsForm,
    props: true,
    meta: {
      parent: 'AppsTable',
      roles: ['dev'],
    },
  },

  {
    path: '/roles',
    name: 'RolesTree',
    component: RolesTree,
    meta: {
      title: '権限',
      icon: 'assignment_ind',
      roles: ['dev'],
    },
  },
  {
    path: '/roles/:id(new)/:section_id([0-9a-f-]+)',
    name: 'RolesFormNew',
    component: RolesForm,
    props: true,
    meta: {
      parent: 'RolesTree',
      roles: ['dev'],
    },
  },
  {
    path: '/roles/:id([0-9a-f-]+)',
    name: 'RolesForm',
    component: RolesForm,
    props: true,
    meta: {
      parent: 'RolesTree',
      roles: ['dev'],
    },
  },
  {
    path: '/sections/:id([0-9a-f-]+|new)',
    name: 'SectionsForm',
    component: SectionsForm,
    props: true,
    meta: {
      parent: 'RolesTree',
      roles: ['dev'],
    },
  },

  {
    path: '/auths',
    name: 'AuthsTable',
    component: AuthsTable,
    meta: {
      title: '認証',
      icon: 'login',
      roles: ['dev'],
    },
  },
  {
    path: '/auths/:id([0-9a-f-]+|new)',
    name: 'AuthsForm',
    component: AuthsForm,
    props: true,
    meta: {
      parent: 'AuthsTable',
      roles: ['dev'],
    },
  },

  {
    path: '/idp_tokens',
    name: 'IdpTokensTable',
    component: IdpTokensTable,
    meta: {
      title: 'IDPトークン',
      icon: 'receipt',
      roles: ['dev'],
    },
  },
  {
    path: '/idp_tokens/:id(new)',
    name: 'IdpTokensForm',
    component: IdpTokensForm,
    props: true,
    meta: {
      parent: 'IdpTokensTable',
      roles: ['dev'],
    },
  },

  {
    path: '/debug',
    name: 'debug',
    component: Debug,
    meta: {
      title: '開発者',
      icon: 'adb',
      roles: ['dev'],
    },
  },

  {
    path: '*',
    component: Notfound,
  },
]

// キョーエイ特別処置: サブディレクトリ運用時のパス指定 -------------------------
const baseURL = process.env.VUE_APP_PATH ?? '/'

const router = new VueRouter({
  mode: 'history',
  base: baseURL,
  routes,
})
//------------------------------------------------------------------------------

rememberQueryString(router)
navigationInhibiter(router)
navigationDirectionDetector(router)

export default router
