
function deepFreeze(object) {
  for(const key of Object.getOwnPropertyNames(object)) {
    let value = object[key];
    if(value && typeof value === "object") {
      object[key] = deepFreeze(value)
    }
  }
  return Object.freeze(object);
}

export default deepFreeze
